import { removeDevice, setSelectedDevice } from 'store/slices/devicesSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteDeviceMutation } from 'services/aiphoneCloud';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getString } from 'shared/utils/LocalizationUtils';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from '../../../shared/constantAwsApi';

interface IDeviceCard {
  devicePublicId: string;
}

const DeviceCard = ({ devicePublicId }: IDeviceCard) => {
  const modelNumber = getString('DeviceCard_ModelNumber');
  const stationName = getString('DeviceCard_StationName');
  const stationNumber = getString('DeviceCard_StationNumber');
  const buttonDelete = getString('Button_Delete_Device');
  const editAdvancedSettings = getString('Edit_Advanced_Settings');
  const device = useSelector((state: RootState) => state.devices.DeviceList[devicePublicId]);
  const imageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
    device.basicInfo.deviceModel,
    device.basicInfo.deviceType
  )}.png`;
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const [deleteDevice, { isLoading }] = useDeleteDeviceMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAdvancedSettings = (devicePublicId: string) => {
    dispatch(setSelectedDevice(devicePublicId));
    navigate(`/site/${sitePublicId}/devices/${devicePublicId}/stationinfo`);
  };

  const handleDeleteDevice = async (devicePublicId: string) => {
    deleteDevice(devicePublicId)
      .unwrap()
      .then(() => {
        dispatch(removeDevice(devicePublicId));
      })
      .catch((error) => {
        console.error('Error deleting device: ', error);
      })
      .finally(() => {
        console.log('Device deleted');
      });
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography gutterBottom variant="h5" component="div">
              {device.basicInfo.macAddress}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>{modelNumber}</strong>
              {getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>{stationName}</strong>
              {device.basicInfo.stationName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>{stationNumber}</strong>
              {device.basicInfo.stationNumber}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box component="img" src={imageUrl} />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            size="small"
            onClick={() => {
              handleAdvancedSettings(devicePublicId);
            }}
            disabled={isLoading}
          >
            {editAdvancedSettings}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default DeviceCard;
