import { Box, Button, Container } from '@mui/material';
import AddUnitDialog from 'features/RemoteManagement/SiteDashboard/TenantManagement/Units/components/AddUnitDialog';
import UnitsControlPanel from 'features/RemoteManagement/SiteDashboard/TenantManagement/Units/components/UnitsControlPanel';
import UnitsDataGrid from 'features/RemoteManagement/SiteDashboard/TenantManagement/Units/components/UnitsDataGrid';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUnitListWithSitePublicIdQuery } from 'services/aiphoneCloud';

interface Props {
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

const strings = {
  backButtonText: 'Back',
  continueButtonText: 'Continue'
};

const AddUnits = ({ handlePreviousStep, handleNextStep }: Props) => {
  const [isAddUnitDialogOpen, setIsAddUnitDialogOpen] = useState(false);
  const sitePublicId = useParams().id;
  const { data, error, isFetching } = useGetUnitListWithSitePublicIdQuery({
    sitePublicId: sitePublicId ?? '',
    qty: 500,
    page: 0
  });

  return (
    <Container maxWidth="xl">
      <Box sx={styles.unitsWrapper}>
        <Box sx={styles.controlPanelWrapper}>
          <UnitsControlPanel setIsOpen={setIsAddUnitDialogOpen} />
        </Box>
        <Box sx={styles.unitsDataGridWrapper}>
          <UnitsDataGrid isFetching={isFetching} />
        </Box>
      </Box>
      <AddUnitDialog isOpen={isAddUnitDialogOpen} setIsOpen={setIsAddUnitDialogOpen} />
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {strings.backButtonText}
        </Button>

        <Button variant="contained" color="primary" type="submit" onClick={handleNextStep}>
          {strings.continueButtonText}
        </Button>
      </Box>
    </Container>
  );
};

export default AddUnits;

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  },
  unitsWrapper: {
    width: '100%',
    minWidth: '1250px',
    height: '100%'
  },
  unitsDataGridWrapper: {
    display: 'flex',
    height: '700px'
  },
  controlPanelWrapper: {
    width: '100%',
    height: '10%'
  }
};
