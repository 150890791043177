import { Box, Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import AddDevicesWizard from 'features/RemoteManagement/SiteDashboard/Devices/wizard/AddDevicesWizard';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import DeviceTabs from '../../shared/components/DeviceTabs';
import DeviceTabPanels from '../../shared/components/DeviceTabPanels';
import { getDeviceTypeModelMapping } from 'store/slices/devicesSlice';

// This component is used to render the existing devices tabs and their content
const ExistingDevices = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState<string | number>('MasterStation');
  const [isAddDevicesWizardOpen, setIsAddDevicesWizardOpen] = useState(false);
  const [, setShouldFetchDevices] = useState(false);
  const [restrictedMacAddresses, setRestrictedMacAddresses] = useState<string[]>([]);
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);

  const allDevices = useSelector((state: RootState) => state.devices.DeviceList);

  const sitePublicId = useParams().id ?? '';

  // This filters out the duplicate devices based on the same MAC Address
  const uniqueDevicesByType = useMemo(() => {
    const uniqueDevices = new Set();
    const sortedDevices: { [key: string]: string[] } = {};

    // Iterate allDevices
    for (const devicePublicId in allDevices) {
      const device = allDevices[devicePublicId];
      const deviceType = device.basicInfo.deviceType;
      const deviceModel = device.basicInfo.deviceModel;

      // Check if device is unique
      if (!uniqueDevices.has(device.basicInfo.macAddress)) {
        uniqueDevices.add(device.basicInfo.macAddress);

        const deviceModelTypeName = getDeviceTypeModelMapping(deviceType, deviceModel);
        if (deviceModelTypeName) {
          if (!sortedDevices[deviceModelTypeName]) {
            sortedDevices[deviceModelTypeName] = [];
          }
          sortedDevices[deviceModelTypeName].push(devicePublicId);
        }
      }
    }

    return sortedDevices;
  }, [allDevices]);

  // Set the current tab index to the first tab that has devices
  useEffect(() => {
    setCurrentTabIndex(
      Object.keys(deviceListByType).find((deviceType) => deviceListByType[deviceType]?.length > 0) || 'MasterStation'
    );
  }, [deviceListByType]);

  return (
    <>
      <Box sx={styles.buttonContainer}>
        <Button variant="contained" onClick={() => setIsAddDevicesWizardOpen(true)}>
          Add Devices
        </Button>
      </Box>
      <TabContext value={currentTabIndex}>
        <DeviceTabs deviceListByType={uniqueDevicesByType} setCurrentTabIndex={setCurrentTabIndex} />
        <DeviceTabPanels deviceListByType={deviceListByType} />
      </TabContext>
      <AddDevicesWizard
        isAddDevicesWizardOpen={isAddDevicesWizardOpen}
        setIsAddDevicesWizardOpen={setIsAddDevicesWizardOpen}
        sitePublicId={sitePublicId}
        setShouldFetchDevices={setShouldFetchDevices}
        restrictedMacAddresses={restrictedMacAddresses}
        setRestrictedMacAddresses={setRestrictedMacAddresses}
      />
    </>
  );
};

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 2
  }
};

export default ExistingDevices;
