import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useBatchCreateAppsMutation, useLazyGetAppWithSiteIdQuery } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { RootState } from 'store';
import { IUnitList } from 'store/slices/unitsSlice';

interface IAddAppToUnitDialogProps {
  selectedUnit: string | null;
  open: boolean;
  setIsOpen: (value: boolean) => void;
}

const AddAppToUnitDialog = ({ selectedUnit, open, setIsOpen }: IAddAppToUnitDialogProps) => {
  const [numberOfApps, setNumberOfApps] = React.useState(0);
  const [batchCreateApps, { isLoading }] = useBatchCreateAppsMutation();
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<string>('');
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const siteId = useParams().id || '';

  const [getAppList] = useLazyGetAppWithSiteIdQuery();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAddApps = () => {
    if (!selectedUnit) {
      return;
    }

    const appList = generateAppList(selectedUnit, numberOfApps, unitList);

    const params = {
      apps: appList
    };

    batchCreateApps(params).unwrap().then(handleSuccess).catch(handleError);
  };

  const generateAppList = (selectedUnit: string, numberOfApps: number, unitList: IUnitList) => {
    const appList = [];
    for (let i = 0; i < numberOfApps; i++) {
      const newApp = {
        appData: {
          stationNumber: `${unitList[selectedUnit].unitNumber}${i + 9}`,
          stationName: `MobileApp${i + 1}`,
          unitPublicId: selectedUnit
        }
      };
      appList.push(newApp);
    }
    return appList;
  };

  const handleSuccess = () => {
    setSuccess('Apps added successfully');
    setIsOpen(false);
    getAppList({
      sitePublicId: siteId || '',
      page: 0,
      qty: 500
    });
  };

  const handleError = (error: Error) => {
    console.log(error);
    setError('Failed to add apps');
    setIsOpen(false);
  };

  const strings = {
    title: 'Number of Apps',
    description: 'Enter between 1-8 apps to add to this unit.'
  };

  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Add Mobile App(s) to Unit</DialogTitle>
        <DialogContent>
          <Box sx={styles.settingsWrapper}>
            <Box sx={styles.descriptionWrapper}>
              <Box sx={styles.title}>{strings.title}</Box>
              <Box sx={styles.description}> {strings.description}</Box>
            </Box>
            <Box sx={styles.inputWrapper}>
              <TextField
                label="Number of Apps"
                type="number"
                value={numberOfApps}
                onChange={(event) => {
                  setNumberOfApps(parseInt(event.target.value));
                  if (parseInt(event.target.value) > 8) {
                    setNumberOfApps(8);
                  }
                }}
                fullWidth
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 8
                  }
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={handleAddApps} loading={isLoading}>
            Add Apps
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        type={'error'}
        time={5000}
        text={error}
        isOpen={!!error}
        onClose={() => {
          setError('');
        }}
      />
      <SnackbarAlert
        type={'success'}
        time={5000}
        text={success}
        isOpen={!!success}
        onClose={() => {
          setSuccess('');
        }}
      />
    </>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  inputWrapper: {
    display: 'flex',
    justifyContent: 'end',
    width: '50%'
  },
  textField: {
    width: '60%'
  }
};

export default AddAppToUnitDialog;
