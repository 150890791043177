import React from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';

interface IOptionType {
  value: string;
  label: string;
}

interface IContactInputProps {
  setNeedSave: (value: boolean) => void;
  fieldName: string;
  value: string;
  contactGroupList: IOptionType[];
  onChange: (fieldName: string, value: string | number) => void;
}

const ContactInput = ({ setNeedSave, fieldName, value, contactGroupList, onChange }: IContactInputProps) => {
  const handleContactInputChange = (event: React.SyntheticEvent<Element, Event>, option: IOptionType | null) => {
    if (option !== null) {
      setNeedSave(true);
      onChange(fieldName, option.value);
    }
  };

  return (
    <Box>
      <Autocomplete
        value={contactGroupList.find((option) => option.value === value) || dropdownOptions[0]}
        defaultValue={contactGroupList[0]}
        options={contactGroupList}
        getOptionLabel={(option) => option.label}
        onChange={handleContactInputChange}
        renderInput={(params) => <TextField {...params} label="Call Trigger" />}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    </Box>
  );
};

export default ContactInput;
