import React, { FC } from 'react';
import { InputLabel, MenuItem, Select, Grid, FormControl, FormHelperText } from '@mui/material';
import { Field, ErrorMessage, useField } from 'formik';
import { useTranslation } from 'react-i18next'; // Import useTranslation for i18n
import { IBranch } from '../../Dialogs/EditRegisteredUserDialog';

interface IBranchList {
  [key: string]: IBranch;
}

interface IBranchSelectProps {
  isBranchesFetching: boolean;
  isCompanySelected: boolean;
  branches: IBranchList;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const BranchSelect: FC<IBranchSelectProps> = ({ isBranchesFetching, isCompanySelected, branches, handleChange }) => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [, meta] = useField('Branch');

  const getSelectLabel = () => {
    if (isBranchesFetching) return t('Loading');
    if (!isCompanySelected) return t('Company_Not_Selected');
    if (!branches || Object.keys(branches).length === 0) return t('Branches_Not_Found');
    return t('Select_Branch');
  };

  const renderBranchOptions = () => {
    if (isBranchesFetching) {
      return (
        <MenuItem value="">
          <em>{t('Loading')}</em>
        </MenuItem>
      );
    }

    if (!isCompanySelected) {
      return (
        <MenuItem value="">
          <em>{t('Company_Not_Selected')}</em>
        </MenuItem>
      );
    }

    if (branches && Object.keys(branches).length === 0) {
      return (
        <MenuItem value="">
          <em>{t('Branches_Not_Found')}</em>
        </MenuItem>
      );
    }

    return Object.entries(branches || {}).map(([publicId, branch]: [string, IBranch]) => (
      <MenuItem key={publicId} value={publicId}>
        {branch.branchName}
      </MenuItem>
    ));
  };

  return (
    <Grid item xs={6}>
      <FormControl size="small" fullWidth error={Boolean(meta.touched && meta.error)}>
        <InputLabel id="branch-label">{getSelectLabel()}</InputLabel>
        <Field
          name="Branch"
          id="Branch"
          as={Select}
          labelId="branch-label"
          label={getSelectLabel()}
          disabled={isBranchesFetching || !isCompanySelected || !branches || Object.keys(branches).length === 0}
          onChange={handleChange}
        >
          {renderBranchOptions()}
        </Field>
        <ErrorMessage name="Branch">{(msg) => <FormHelperText error>{msg}</FormHelperText>}</ErrorMessage>
      </FormControl>
    </Grid>
  );
};

export default BranchSelect;
