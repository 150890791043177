import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUpdateUnitMutation } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { RootState } from 'store';

const UnitInfoCard = () => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [updateUnit] = useUpdateUnitMutation();
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const unitPublicId = useParams().unitid || '';
  const selectedUnit = unitList[unitPublicId];

  const saveUnitDetails = (values: any) => {
    const params = {
      unitData: {
        ...selectedUnit,
        unitNumber: values.unitNumber,
        unitName: values.unitName
      },
      unitPublicId: selectedUnit.publicId
    };

    updateUnit(params)
      .unwrap()
      .then(() => {
        setSuccess('Unit details saved successfully');
      })
      .catch((error) => {
        setError('Failed to save unit details');
      });
  };

  if (!selectedUnit) {
    return (
      <>
        <Card sx={styles.unitCard}>
          <Typography variant="sectionHeader">Loading Unit...</Typography>
          <Box></Box>
        </Card>
      </>
    );
  }

  const initialValues = {
    unitNumber: selectedUnit?.unitNumber || '',
    unitName: selectedUnit?.unitName || ''
  };

  return (
    <>
      <Card sx={styles.unitCard}>
        <Typography variant="sectionHeader">Unit {selectedUnit.unitNumber || ''}</Typography>
        <Box>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={saveUnitDetails}>
            {({ ...props }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="unitNumber" label="Unit Number" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="unitName" label="Unit Name" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={styles.loadingButton}
                      loading={props.isSubmitting}
                      disabled={
                        props.values.unitNumber === selectedUnit.unitNumber &&
                        props.values.unitName === selectedUnit.unitName
                      }
                      onClick={saveUnitDetails}
                    >
                      Save Unit Details
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
      <SnackbarAlert
        type={error ? 'error' : 'success'}
        time={6000}
        text={error || success || ''}
        isOpen={!!error || !!success}
        onClose={() => {
          setError(null);
          setSuccess(null);
        }}
      />
    </>
  );
};

const styles = {
  unitCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '20px'
  },
  unitInputsWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: '10px'
  },
  unitDetails: {
    marginTop: '20px'
  },
  loadingButton: {
    float: 'right'
  }
};

export default UnitInfoCard;
