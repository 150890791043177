import { useRef, useEffect } from 'react';
import { useStripeFunctions } from 'shared/api/Aws/awsApi';
import { ACLUser, SimBillingSite } from 'features/SimBilling/Types/SimBillingTypes';
import { getAlpha2CodeForCountry } from 'features/SimBilling/Utils/SimBillingUtils';

//TODO -------------------------------------------------------------------
// support for multilingual using libraries such as react-intl or i18next.
import strings from 'features/SimBilling/Types/en.json';
import { ApiError } from 'shared/api/ApiError';
import { analyticsService } from 'services/googleAnalyticsService';
import { EnumList } from 'shared/utils/EnumUtils';
//------------------------------------------------------------------------

const useSubscriptionActions = (
  user: ACLUser,
  siteDetails: SimBillingSite,
  currentSubscription: any, // TODO Define "subscription" type
  handleResponse: (message: string, status: boolean, showPaymentUpdateUrl?: boolean) => void | null,
  enumList: EnumList['country']
) => {
  const { subscriptionActions } = strings;

  const allSIMsDeactivated = siteDetails.GwAndSim.every((sim) => sim.SimStatus === 'DEACTIVATED');

  const handleResponseRef = useRef(handleResponse);

  useEffect(() => {
    handleResponseRef.current = handleResponse;
  }, [handleResponse]);

  const siteAddress = {
    line1: siteDetails.Address1,
    city: siteDetails.City,
    state: siteDetails.State,
    postal_code: siteDetails.ZipCode,
    country: getAlpha2CodeForCountry(siteDetails.CountryCode, enumList)
  };

  const aclUserCompanyAddress = {
    line1: user.CompanyAddress1,
    city: user.CompanyCity,
    state: user.CompanyState,
    postal_code: user.CompanyZipCode,
    country: getAlpha2CodeForCountry(user.CompanyCountryCode, enumList)
  };

  const buyAdditionalData = async (
    additionalDataDescription: string,
    additionalDataAmount: number,
    additionalDataPrice: number
  ) => {
    try {
      if (!siteDetails.SubscriptionId) {
        handleResponse(subscriptionActions.notFoundError, false);
        return;
      }
      const addressForTax = siteDetails.Payer === 'Dealer' ? aclUserCompanyAddress : siteAddress;
      const taxExempt = user.DutyFree === 1 ? 'true' : 'false';

      if (allSIMsDeactivated) {
        // Check if the new total data (current + additional) is less than or equal to what's already used
        const projectedTotalData: number =
          siteDetails.SubscriptionData + siteDetails.AdditionalData + additionalDataAmount;
        if (siteDetails.CurrentDataUsage > projectedTotalData) {
          handleResponse(subscriptionActions.subscribe.subscriptionDataUpdateError, false);
          return undefined;
        }
      }

      const params = {
        stripeCustomerId: user.StripeCustomerId,
        subscriptionId: siteDetails.SubscriptionId,
        additionalDataAmountCents: additionalDataPrice * 100,
        additionalDataDescription: additionalDataDescription,
        dataAmount: additionalDataAmount,
        dataUnit: 'GB',
        action: 'buyAdditionalData',
        taxExempt,
        addressForTax,
        aclSiteId: siteDetails.AclSiteId.toString(),
        aclUserId: user.AclUserId.toString()
      };
      await useStripeFunctions(params);
      handleResponse(subscriptionActions.buyData.success, true);
      return;
    } catch (error) {
      let message = subscriptionActions.buyData.error;
      let showUpdatePaymentLink = false;

      if (error instanceof ApiError) {
        message = error.message;
        showUpdatePaymentLink = true;
      }
      handleResponse(message, false, showUpdatePaymentLink);
      return;
    }
  };

  const handleSubscribe = async (subscription: any) => {
    try {
      analyticsService.trackButtonClick('Sim Billing', 'Subscribe');
      const addressForTax = user.AuthorityCode === 'Dealer' ? aclUserCompanyAddress : siteAddress;
      const taxExempt = user.DutyFree === 1 ? 'true' : 'false';

      if (siteDetails.SubscriptionId) {
        const newPlanData = subscription.dataAmount;
        const currentPlanData = currentSubscription.dataAmount;
        if (!newPlanData || !currentPlanData) {
          handleResponse('', false);
          return;
        } else {
          if (newPlanData < currentPlanData && siteDetails.CurrentDataUsage > newPlanData) {
            handleResponse(subscriptionActions.subscribe.planDownGradeError, false);
            return;
          } else if (allSIMsDeactivated) {
            // Check if the new total data (new plan data + additional) is less than or equal to what's already used
            const projectedTotalData: number = newPlanData + siteDetails.AdditionalData;
            if (siteDetails.CurrentDataUsage > projectedTotalData) {
              handleResponse(subscriptionActions.subscribe.subscriptionDataUpdateError, false);
              return;
            }
          }
        }
        const params = {
          subscriptionId: siteDetails.SubscriptionId,
          newPriceId: subscription.priceID,
          addressForTax,
          taxExempt,
          action: 'updateSubscription',
          aclSiteId: siteDetails.AclSiteId,
          aclUserId: user.AclUserId,
          dataAmount: subscription.dataAmount,
          dataUnit: subscription.dataUnit
        };
        await useStripeFunctions(params);
        handleResponse(subscriptionActions.subscribe.updateSuccess, true);
        return;
      } else {
        const params = {
          action: 'makeSubscription',
          taxExempt,
          addressForTax,
          emailAddress: user.EmailAddress,
          stripeCustomerId: user.StripeCustomerId,
          newPriceId: subscription.priceID,
          aclSiteId: siteDetails.AclSiteId,
          aclSiteName: siteDetails.SiteName,
          aclSiteAddress: `${siteAddress.line1}, ${siteAddress.city}, ${siteAddress.state}, ${siteAddress.postal_code}, ${siteAddress.country}`,
          aclUserId: user.AclUserId,
          aclUserCompanyAddress: `${aclUserCompanyAddress.line1}, ${aclUserCompanyAddress.city}, ${aclUserCompanyAddress.state}, ${aclUserCompanyAddress.postal_code}, ${aclUserCompanyAddress.country}`,
          dataAmount: subscription.dataAmount,
          dataUnit: subscription.dataUnit,
          reactAppStage: process.env.REACT_APP_STAGE,
          aclUserPublicId: user.PublicId
        };

        await useStripeFunctions(params).then((response) => {
          if (!response?.data) {
            handleResponse(subscriptionActions.subscribe.error, false);
            return;
          } else {
            window.location.href = response.data.message;
          }
        });
      }
    } catch (error) {
      let message = subscriptionActions.subscribe.error;
      let showUpdatePaymentLink = false;

      if (error instanceof ApiError) {
        message = error.message;
        showUpdatePaymentLink = true;
      }
      handleResponse(message, false, showUpdatePaymentLink);
      return;
    }
  };

  const cancelSubscription = async () => {
    try {
      if (!siteDetails.SubscriptionId) {
        handleResponse(subscriptionActions.notFoundError, false);
        return;
      }
      const params = {
        subscriptionId: siteDetails.SubscriptionId,
        aclSiteId: siteDetails.AclSiteId.toString(),
        action: 'cancelSubscription',
        aclUserId: user.AclUserId.toString()
      };
      await useStripeFunctions(params);
      handleResponse(subscriptionActions.cancelSubscription.success, true);
      return;
    } catch (error) {
      handleResponse(subscriptionActions.cancelSubscription.error, false);
      return;
    }
  };

  const getPaymentUpdateSession = async (returnUrl: string) => {
    try {
      const params = {
        stripeCustomerId: user.StripeCustomerId,
        returnUrl: returnUrl,
        action: 'getPaymentUpdateUrl'
      };

      const response = await useStripeFunctions(params);
      if (!response?.data) {
        handleResponse(subscriptionActions.subscribe.error, false);
        return undefined;
      } else {
        return response.data.message;
      }
    } catch (error) {
      handleResponse(subscriptionActions.updatePaymentSession.error, false);
      return undefined;
    }
  };

  return { buyAdditionalData, handleSubscribe, cancelSubscription, getPaymentUpdateSession };
};

export default useSubscriptionActions;
