import { useState } from 'react';
import { Box, Button, Collapse, Grid, IconButton, InputAdornment, TextField, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ConfirmDialog from 'features/SimBilling/Components/UiParts/ConfirmDialog';
import { useSubscriptionActions } from 'features/SimBilling/Hooks';
import { SubscriptionStatus } from 'features/SimBilling/Hooks/useFetchSubscriptionDetail';
import { ACLUser, SimBillingSite, SubscriptionPlan } from 'features/SimBilling/Types/SimBillingTypes';
import { EnumList } from 'shared/utils/EnumUtils';
import { DialogActionTypes } from './SiteDetail';

//TODO -------------------------------------------------------------------
// support for multilingual using libraries such as react-intl or i18next.
import strings from 'features/SimBilling/Types/en.json';
//------------------------------------------------------------------------

const PRICE_PER_HALF_GB = 10;

interface SubscriptionActionsProps {
  user: ACLUser;
  siteDetails: SimBillingSite;
  subscriptionStatus: SubscriptionStatus | null;
  currentSubscription: SubscriptionPlan;
  enumList: EnumList;
  handleResponse: any;
}

const SubscriptionActions = ({
  user,
  siteDetails,
  subscriptionStatus,
  currentSubscription,
  enumList,
  handleResponse
}: SubscriptionActionsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogActionType, setDialogActionType] = useState(DialogActionTypes.NOT_SET);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogLoadingMessage, setDialogLoadingMessage] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [showQuantitySelector, setShowQuantitySelector] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [newSubscription, setNewSubscription] = useState(false);

  const { buyAdditionalData, handleSubscribe, cancelSubscription } = useSubscriptionActions(
    user,
    siteDetails,
    currentSubscription,
    handleResponse,
    enumList.country
  );

  const openDialog = (
    actionType: string,
    content: string,
    title: string,
    loadingMessage: string,
    newSubscription?: any
  ) => {
    setDialogContent(content);
    setDialogTitle(title);
    setDialogActionType(actionType);
    setIsDialogOpen(true);
    setDialogLoadingMessage(loadingMessage);
    if (newSubscription) setNewSubscription(newSubscription);
  };

  const openBuyDataDialog = () => {
    const totalPrice = PRICE_PER_HALF_GB * selectedQuantity;
    const totalAdditionalData = selectedQuantity * 0.5;
    const buyDataDialogContent = `You are about to purchase ${totalAdditionalData}GB for $${totalPrice}. Do you want to proceed?`;
    openDialog(DialogActionTypes.BUY_DATA, buyDataDialogContent, 'Buy Data', 'Buying data...');
  };

  const openCancelSubscriptionDialog = () => {
    openDialog(
      DialogActionTypes.CANCEL_SUBSCRIPTION,
      strings.site.cancelSubscriptionDialogContent,
      strings.site.cancelSubscriptionDialogTitle,
      strings.site.cancelSubscriptionLoading
    );
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setDialogMessage('');
  };

  const handleDialogConfirm = async () => {
    setDialogLoading(true);
    if (dialogActionType === DialogActionTypes.BUY_DATA) {
      const totalPrice = PRICE_PER_HALF_GB * selectedQuantity;
      const totalAdditionalData = selectedQuantity * 0.5;
      const addOnDataDescription = `Additional ${totalAdditionalData}GB Data`;
      await buyAdditionalData(addOnDataDescription, totalAdditionalData, totalPrice);
    } else if (dialogActionType === DialogActionTypes.CANCEL_SUBSCRIPTION) {
      await cancelSubscription();
    } else if (dialogActionType === DialogActionTypes.SUBSCRIBE) {
      await handleSubscribe(newSubscription);
    }
  };

  const increment = () => {
    setSelectedQuantity(selectedQuantity + 1);
  };

  const decrement = () => {
    if (selectedQuantity > 0) {
      setSelectedQuantity(selectedQuantity - 1);
    }
  };

  const openBuyDataOptions = () => {
    setShowQuantitySelector(!showQuantitySelector);
  };

  const isPastDue = subscriptionStatus && subscriptionStatus === 'past_due';
  const subscriptionInactive = subscriptionStatus && subscriptionStatus !== 'active';
  const isSubscriptionCreatedByCurrentUser = siteDetails.SubscriptionCreatedByAclUserPublicId === user.PublicId;

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            onClick={openCancelSubscriptionDialog}
            disabled={isPastDue || subscriptionInactive === true}
          >
            Cancel Subscription
          </Button>
        </Grid>

        {false && (
          <Grid item>
            <Button
              variant="contained"
              onClick={openBuyDataOptions}
              disabled={isPastDue || subscriptionInactive === true || !isSubscriptionCreatedByCurrentUser}
            >
              Add Additional Data
            </Button>
          </Grid>
        )}
      </Grid>
      <Collapse in={showQuantitySelector}>
        <Box sx={styles.topAndBottomMargin}>
          <Typography variant="h6">{`Buy .5 GB for $${PRICE_PER_HALF_GB}`}</Typography>
          <Box display="block" alignItems="center" sx={styles.boxWithBottomMargin}>
            <Grid container style={styles.dataQuantityContainer}>
              <StyledTextField
                type="text"
                label="Quantity"
                value={selectedQuantity || 0}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={decrement} size="medium">
                        <RemoveIcon />
                      </IconButton>
                      <IconButton onClick={increment} size="medium">
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
              />
            </Grid>
            <Button
              variant="contained"
              onClick={openBuyDataDialog}
              disabled={selectedQuantity < 1 || isPastDue || subscriptionInactive === true}
            >
              Buy Additional Data
            </Button>
          </Box>
        </Box>
      </Collapse>
      <ConfirmDialog
        isOpen={isDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        onClose={handleCloseDialog}
        onConfirm={handleDialogConfirm}
        isLoading={dialogLoading}
        loadingMessage={dialogLoadingMessage}
        message={dialogMessage}
        messageType={'success'}
      />
    </Box>
  );
};

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    color: '#333',
    fontWeight: 'bold',
    height: '10px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#888'
    },
    '&:hover fieldset': {
      borderColor: '#555'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7f8c8d'
    }
  },
  '& .MuiInputAdornment-root .MuiIconButton-root': {
    padding: '10px'
  },
  '& input[type=number]': {
    MozAppearance: 'textfield', // for Firefox
    WebkitAppearance: 'none' // for Chrome and Safari
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none', // for Chrome and Safari
    margin: 0
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none', // for Chrome and Safari
    margin: 0
  }
}));

const styles = {
  boxWithBottomMargin: { mb: 2 },
  button: { marginRight: 2 },
  labelsWithMargin: {
    mb: 2,
    mt: 2
  },
  dataQuantityContainer: {
    margin: '8px 0 ',
    width: `170px`
  },
  topAndBottomMargin: {
    my: 2
  },
  subscriptionAlert: {
    backgroundColor: '#ffe3e3',
    border: '1px solid #ffcccc',
    padding: '16px',
    marginTop: '16px',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  subscriptionAlertIcon: {
    marginRight: '8px',
    color: '#d32f2f'
  },
  subscriptionAlertText: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold'
  }
};

export default SubscriptionActions;
