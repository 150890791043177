import Dialog from '@mui/material/Dialog';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import {
  useGetAssignableRolesQuery,
  useGetBranchWithPublicIdQuery,
  useAdminUpdateUserMutation,
  useRemoveUserRoleMutation
} from 'services/aiphoneCloud';
import { CloudUser, ContextType } from 'store/slices/usersSlice';
import { Field, Form, Formik } from 'formik';
import RoleDescriptions from '../RoleDescriptions';
import { LoadingButton } from '@mui/lab';
import { CloudRole, UserInfo } from 'shared/utils/UserUtils';
import * as yup from 'yup';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface IEditUserDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedUser?: CloudUser;
  setErrorMessage: (message: string) => void;
  setSuccessMessage: (message: string) => void;
  setIsError: (isError: boolean) => void;
  setIsSuccess: (isSuccess: boolean) => void;
}

const EditUserDialog = ({
  isOpen,
  setIsOpen,
  selectedUser,
  setErrorMessage,
  setSuccessMessage,
  setIsError,
  setIsSuccess
}: IEditUserDialogProps) => {
  const { t } = useTranslation();
  const branchPublicId = Object.keys(selectedUser?.permissions?.branch || {})[0] || '';
  const { data: branchData, isFetching } = useGetBranchWithPublicIdQuery(branchPublicId, { skip: !isOpen });
  const currentUser = useSelector((state: RootState) => state.users.currentUser?.publicId);
  const [, setRole] = React.useState('');
  const { data: roles, isFetching: isFetchingRoles } = useGetAssignableRolesQuery({
    contextPublicId: branchPublicId,
    contextType: ContextType.BRANCH
  });

  const hasManagerRole = new UserInfo().isBranchManager();
  const [removeUserRole] = useRemoveUserRoleMutation();

  const [adminUpdateUser] = useAdminUpdateUserMutation();

  const initialValues = {
    firstName: selectedUser?.firstName,
    lastName: selectedUser?.lastName,
    email: selectedUser?.email,
    phoneNumber: selectedUser?.phoneNumber,
    role: selectedUser?.permissions?.branch[branchPublicId].roleList[0].publicId || '',
    companyName: !isFetching ? branchData?.company.name : '',
    branchName: !isFetching ? branchData?.branchName : ''
  };

  const handleEditUser = async (values: any) => {
    let updatedUser;
    if (hasManagerRole) {
      updatedUser = {
        publicId: selectedUser?.publicId,
        roleData: {
          initialRole: selectedUser?.permissions.branch[branchPublicId].roleList[0].publicId,
          newRole: values.role,
          branchPublicId: branchPublicId
        }
      };
    } else {
      updatedUser = {
        publicId: selectedUser?.publicId,
        userData: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: selectedUser?.email,
          phoneNumber: selectedUser?.phoneNumber,
          countryId: selectedUser?.countryId,
          language: selectedUser?.languageId,
          phoneExtension: selectedUser?.phoneExtension,
          marketingOptIn: selectedUser?.marketingOptIn
        },
        roleData: {
          initialRole: selectedUser?.permissions.branch[branchPublicId].roleList[0].publicId,
          newRole: values.role,
          branchPublicId: branchPublicId
        }
      };
    }

    await adminUpdateUser(updatedUser).then((response) => {
      if ('error' in response && response.error) {
        setErrorMessage('Failed to update user');
        setIsError(true);
      } else {
        setSuccessMessage('User updated successfully');
        setIsSuccess(true);
        setIsOpen(false);
      }
    });
  };

  const handleRemoveRole = async () => {
    const assignedBranchRole = selectedUser?.permissions.branch[branchPublicId].roleList[0].publicId;

    const removeRoleData = {
      userPublicId: selectedUser?.publicId,
      rolePublicId: assignedBranchRole,
      branchPublicId: branchPublicId
    };

    await removeUserRole(removeRoleData).then((response) => {
      if ('error' in response && response.error) {
        setErrorMessage('Failed to remove role');
        setIsError(true);
      } else {
        setSuccessMessage('Role removed successfully');
        setIsSuccess(true);
        setIsOpen(false);
      }
    });
  };

  const isSelectedUserCurrentUser = selectedUser?.publicId === currentUser;

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(t('First_Name_Required')),
    lastName: yup.string().required(t('Last_Name_Required'))
  });

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} aria-labelledby="" maxWidth="lg" fullWidth>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>
            {t('Edit_User')} - {selectedUser?.firstName} {selectedUser?.lastName}
          </DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            handleEditUser(values);
          }}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, errors, touched, values, dirty, handleChange }) => (
            <Form>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{t('User_Information')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="firstName"
                      as={TextField}
                      sx={styles.inputField}
                      label={t('First_Name')}
                      size="small"
                      disabled={hasManagerRole}
                      helperText={touched.firstName && errors.firstName ? errors.firstName : ''}
                      error={touched.firstName && !!errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="lastName"
                      as={TextField}
                      sx={styles.inputField}
                      label={t('Last_Name')}
                      size="small"
                      disabled={hasManagerRole}
                      helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                      error={touched.lastName && !!errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="email"
                      as={TextField}
                      sx={styles.inputField}
                      label={t('Email')}
                      size="small"
                      helperText={touched.email && errors.email ? errors.email : ''}
                      error={touched.email && !!errors.email}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="phoneNumber"
                      as={TextField}
                      sx={styles.inputField}
                      label={t('Phone_Number')}
                      size="small"
                      helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
                      error={touched.phoneNumber && !!errors.phoneNumber}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
              {isFetching ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h5" sx={styles.sectionHeader}>
                          {t('Company_Information')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="companyName"
                          as={TextField}
                          sx={styles.inputField}
                          label={t('Company_Name')}
                          size="small"
                          helperText={touched.companyName && errors.companyName ? errors.companyName : ''}
                          error={touched.companyName && !!errors.companyName}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" sx={styles.sectionHeader}>
                          {t('Branch_Information')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="branchName"
                          as={TextField}
                          sx={styles.inputField}
                          label={t('Branch_Name')}
                          size="small"
                          helperText={touched.branchName && errors.branchName ? errors.branchName : ''}
                          error={touched.branchName && !!errors.branchName}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          sx={styles.inputField}
                          size="small"
                          fullWidth
                          disabled={
                            selectedUser?.permissions?.branch[branchPublicId].roleList[0].roleName === 'Admin' &&
                            hasManagerRole
                          }
                          helperText={touched.role && errors.role ? errors.role : ''}
                          error={touched.role && !!errors.role}
                        >
                          <InputLabel id="role-label">{t('Select_A_Role')}</InputLabel>
                          <Field
                            name="role"
                            id="role"
                            as={Select}
                            labelId="role-label"
                            label={t('Select_A_Role')}
                            onChange={(event) => {
                              handleChange(event);
                              setRole(event.target.value as string);
                            }}
                            helperText={touched.role && errors.role ? errors.role : ''}
                            error={touched.role && !!errors.role}
                          >
                            <MenuItem value="Remove">{t('Remove')}</MenuItem>
                            {isFetchingRoles
                              ? []
                              : [...roles]
                                  .filter((role) => !role.roleName.includes('Property'))
                                  .map((role) => (
                                    <MenuItem
                                      key={role.publicId}
                                      value={role.publicId}
                                      disabled={role.roleName === CloudRole.ADMIN && hasManagerRole}
                                    >
                                      {role.roleName}
                                    </MenuItem>
                                  ))}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <RoleDescriptions roleName={roles.find((id) => id.publicId === values.role)?.roleName} />
                      </Grid>
                      {values.role === 'Remove' && (
                        <Grid item xs={12}>
                          <Alert severity="warning">
                            {isSelectedUserCurrentUser ? (
                              <Typography variant="subtitle1">{t('Cannot_Remove_Own_Role')}</Typography>
                            ) : (
                              <Typography variant="subtitle1">{t('Removing_Role_Will_Remove_Access')}</Typography>
                            )}
                          </Alert>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </>
              )}
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <Typography>{t('Cancel')}</Typography>
                </Button>
                {values.role === 'Remove' ? (
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      handleRemoveRole();
                    }}
                    loading={isSubmitting}
                    disabled={isSelectedUserCurrentUser}
                  >
                    <Typography>{t('Remove_User_From_Branch')}</Typography>
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting || !dirty}
                    loading={isSubmitting}
                  >
                    <Typography>{t('Save')}</Typography>
                  </LoadingButton>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  sectionHeader: {
    marginTop: 1
  }
};

export default EditUserDialog;
function removeUserRole(removeRoleData: {
  userPublicId: string | undefined;
  rolePublicId: string | undefined;
  branchPublicId: string;
}) {
  throw new Error('Function not implemented.');
}
