import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { FormValues } from 'features/QuikSpec/Types/QuikspecFormTypes';
import { getBranchSpecs } from 'shared/api/Aws/QuikSpecApi';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getString } from 'shared/utils/LocalizationUtils';

interface BranchQuikspecsDataGridProps {
  branchPublicId: string;
}

const BranchQuikspecsDataGrid = ({ branchPublicId }: BranchQuikspecsDataGridProps) => {
  const buttonClose = getString('Button_Close');
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [quikspecs, setQuikspecs] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const selectedSpecTitle = getString('Title_Selected_Spec');
  const siteName = getString('Site_Name');
  const quantity = getString('Quantity');
  const model = getString('Model');

  const callingUserPublicId = localStorage.getItem('userId') ?? '';

  const getBranches = useSelector((state: RootState) => state.users.currentUser?.permissions?.branch) || {};
  const branchList = Object.keys(getBranches).map((branchId) => {
    return {
      branchId,
      branchName: getBranches[branchId]?.branchName
    };
  });

  const handleClose = () => {
    setOpen(false);
  };

  const getAllQuikspecs = async () => {
    setLoading(true);

    try {
      const getBranchSpecsRes = await getBranchSpecs({ branchPublicId, callingUserPublicId });
      const files = getBranchSpecsRes?.data?.files;
      if (files) {
        const filesAsObjects = files.map((file: string) => JSON.parse(file));
        setQuikspecs(filesAsObjects);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error getting Quikspec data: ', error);
    } finally {
      setLoading(false);
    }
  };

  const filterOutDuplicateQuikspecs = (quikspecs: FormValues[]) => {
    const filteredQuikspecs = quikspecs.filter((quikspec, index, self) => {
      return index === self.findIndex((t) => t.quikspecId === quikspec.quikspecId);
    });
    return filteredQuikspecs;
  };

  useEffect(() => {
    getAllQuikspecs();
  }, []);

  return (
    <div>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DataGrid
            rows={filterOutDuplicateQuikspecs(quikspecs).map((item: FormValues) => ({
              id: item.quikspecId,
              name: item.description,
              system: item.systemName,
              createdDate: item.createdDate,
              lastUpdateDate: item.lastUpdateDate,
              createdBy: item.userEmail
            }))}
            columns={[
              { field: 'name', headerName: 'Name', flex: 1, minWidth: 100 },
              { field: 'system', headerName: 'System', flex: 1, minWidth: 100 },
              { field: 'createdDate', headerName: 'Created Date', flex: 1, minWidth: 100 },
              { field: 'lastUpdateDate', headerName: 'Last Updated', flex: 1, minWidth: 100 },
              { field: 'createdBy', headerName: 'Created By', flex: 1, minWidth: 100 }
            ]}
            initialState={{
              sorting: {
                sortModel: [{ field: 'lastUpdateDate', sort: 'desc' }]
              }
            }}
            onRowClick={(row) => {
              const selected = quikspecs.find((item: FormValues) => item.quikspecId === row.id);
              setSelectedRow(selected);
              setOpen(true);
            }}
          />
          <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>{selectedSpecTitle}</DialogTitle>
            {selectedRow && (
              <DialogContent>
                <Box sx={styles.sectionBox}>
                  {selectedRow && (
                    <Typography variant="h6" component={'h6'}>
                      {siteName} {selectedRow.quikspecName}
                    </Typography>
                  )}
                  <TableContainer component={Box}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{quantity}</TableCell>
                          <TableCell>{model}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedRow.items.map((item) => (
                          <TableRow key={item.itemName}>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>{item.itemName}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={handleClose}>{buttonClose}</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

const styles = {
  sectionBox: {
    padding: '1rem',
    textAlign: 'center'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f2f2'
    }
  },
  tableBody: {
    borderRadius: '1rem'
  },
  column: {
    flex: 1,
    textAlign: 'center'
  },
  editColumn: {
    flex: 0.5,
    textAlign: 'center'
  }
};

export default BranchQuikspecsDataGrid;
