import { Box, List } from '@mui/material';
import { IUnit } from 'store/slices/unitsSlice';
import AddedAppUnitsItem from './AddedAppUnitsItem';
import { useTranslation } from 'react-i18next';

interface IAppUnitsProps {
  appUnits: IUnit[];
  callDestinationAppUnit: { publicId: string }[];

  onSelectAppUnit: (id: string) => void;
  handleRemoveAppUnit: (id: string) => void;
}

const AddedAppUnits = ({ appUnits, callDestinationAppUnit, onSelectAppUnit, handleRemoveAppUnit }: IAppUnitsProps) => {
  const callDestinationAppUnitId = callDestinationAppUnit[0]?.publicId || '';
  const { t } = useTranslation();

  return (
    <Box sx={styles.unitsWrapper}>
      <Box sx={styles.title}>{t('App_Group')}</Box>
      <List>
        {appUnits.length > 0 &&
          appUnits.map((appUnit) => (
            <AddedAppUnitsItem
              key={appUnit?.publicId}
              AppUnitId={appUnit?.publicId}
              isSelected={callDestinationAppUnitId === appUnit?.publicId}
              onSelectAppUnit={onSelectAppUnit}
              handleRemoveAppUnit={handleRemoveAppUnit}
            />
          ))}
      </List>
    </Box>
  );
};

const styles = {
  unitsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  title: {
    fontSize: '18px',
    padding: '10px',
    marginLeft: '15px',
    backgroundColor: '#F0F0F0'
  }
};

export default AddedAppUnits;
