import { Alert, AlertTitle, Box, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import { twHidden, twSystemConstraints } from '../../../Types/TwoWireTypes';
import { StandardSystems } from 'features/QuikSpec/Types/QuikSpecTypes';
import { updateFormValuesTW } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesTW';
import QuikspecCategoryTwoWireQuestions from '../../UiParts/QuikspecCategoryTwoWireQuestions';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import QuikspecSystemConstraints from '../../UiParts/QuikspecSystemConstraints';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';
import { getString } from 'shared/utils/LocalizationUtils';

const validationSchema = Yup.object().shape({
  quikspecName: Yup.string().required('Quikspec Name is required'),
  marketType: Yup.string().required('Market Type is required'),
  items: Yup.array().of(
    Yup.object().shape({
      itemName: Yup.string().required('Item Name is required'),
      quantity: Yup.number().min(0, 'Quantity must be greater than or equal to 0').required('Quantity is required')
    })
  )
});

const QuikspecTwoWire = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = StandardSystems.findIndex((system) => system.siteName === siteName);
  const systemName = StandardSystems[siteIndex].seriesName;
  const systemforC2 = StandardSystems[siteIndex].systemforC2;

  const categories = [...twHidden];
  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);
  const alertError = getString('Error');

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      //filter out items with quantity set to zero in order to submit quikspec
      values = updateFormValuesTW(values);

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;

      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors({ submit: 'Something went wrong. Please try again or contact customer support.' });
    }
  };

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              {/* Render QuikspecCategoryTabs for each category */}
              <QuikspecSystemConstraints systemConstraints={twSystemConstraints} />
              <QuikspecCategoryTwoWireQuestions categories={twHidden} />
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{alertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton editing={state.formValues != undefined} errors={errors} submitting={isSubmitting} />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecTwoWire;
