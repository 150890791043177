import { Box, Card, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import EntranceStationCard from './components/QRAccessCodesComponents/EntranceStationCard';
import QRAccessControlPanel from './components/QRAccessCodesComponents/QRAccessCodeControlPanel';
import { useParams } from 'react-router-dom';

export interface IEntrancePanelPayload {
  [key: string]: {
    displayUnlockCodeList: ICodeListEntry[];
    qrCodeEnabled?: boolean;
  };
}

export interface ICodeListEntry {
  devicePublicId: string;
  unitPublicId: string;
  accessCode: string;
}

const QRAccessCodes = () => {
  const [entrancePanelsToUpdate, setEntrancePanelsToUpdate] = React.useState<IEntrancePanelPayload>({});
  const [needsSave, setNeedsSave] = useState<boolean>(false);
  const [sharedAccessCode, setSharedAccessCode] = useState<string>('');
  const unitPublicId = useParams().unitid || '';
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const entranceStation = deviceListByType['EntranceStation'];

  useEffect(() => {
    if (Object.keys(entrancePanelsToUpdate).length > 0) {
      setNeedsSave(true);
    }
  }, [entrancePanelsToUpdate]);

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={styles.mb}>
          <QRAccessControlPanel
            needsSave={needsSave}
            setNeedsSave={setNeedsSave}
            setSharedAccessCode={setSharedAccessCode}
            entrancePanelsToUpdate={entrancePanelsToUpdate}
          />
        </Box>
        <Box>
          <Card sx={styles.cardWrapper}>
            <Grid container spacing={2}>
              {entranceStation &&
                entranceStation.map((devicePublicId) => {
                  return (
                    <Grid item xs={12} sm={6} key={devicePublicId}>
                      <EntranceStationCard
                        devicePublicId={devicePublicId}
                        unitPublicId={unitPublicId}
                        entrancePanelsToUpdate={entrancePanelsToUpdate}
                        setEntrancePanelsToUpdate={setEntrancePanelsToUpdate}
                        sharedAccessCode={sharedAccessCode}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Card>
        </Box>
      </Container>
    </>
  );
};

const styles = {
  cardWrapper: {
    padding: '15px'
  },
  mb: {
    marginBottom: '20px'
  }
};

export default QRAccessCodes;
