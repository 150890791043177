import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';

interface IInputCallDialogProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callGroupNumber: number;
}

interface IRowData {
  id: string;
  stationName: string;
  stationNumber: string;
  unitName: string;
  unitNumber: string;
}

const strings = {
  title: 'Input Call Destination',
  description: 'Preview the selected call group below. Call Groups are set in the Call Settings page.',
  selectGroup: 'Call Group'
};

const InputCallDialog = ({ isOpen, setIsOpen, callGroupNumber }: IInputCallDialogProps) => {
  const [devicesInCallGroup, setDevicesInCallGroup] = useState<Array<any>>([]); // Default [empty array]
  const selectedDevicePublicId = useSelector((state: RootState) => state.devices.SelectedDevice) || '';
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const contactGroupDevices = useSelector(
    (state: RootState) => state.devices.DeviceList[selectedDevicePublicId]?.callSettings?.contactGroup
  );
  const navigate = useNavigate();

  const columns = [
    { field: 'stationNumber', headerName: 'Station Number', width: 125 },
    { field: 'stationName', headerName: 'Station Name', width: 150 },
    { field: 'unitNumber', headerName: 'Unit Number', width: 100 },
    { field: 'unitName', headerName: 'Unit Name', width: 150 }
  ];

  const generateRows = () => {
    const rows: IRowData[] = [];
    // Check if contactGroupDevices and contactGroupDevices.contactGroup are defined
    if (contactGroupDevices) {
      contactGroupDevices.forEach((device) => {
        if (device.contactGroupNumber === callGroupNumber) {
          const deviceData = deviceList[device.targetDevicePublicId];
          // Ensure deviceData and deviceData.basicInfo are defined
          if (deviceData && deviceData.basicInfo) {
            const unitData: Unit | null = deviceData.unitPublicId ? unitList[deviceData.unitPublicId] : null;
            rows.push({
              id: deviceData.publicId,
              stationNumber: deviceData.basicInfo.stationNumber,
              stationName: deviceData.basicInfo.stationName,
              unitNumber: unitData ? unitData.unitNumber : '-',
              unitName: unitData ? unitData.unitName : '-'
            });
          }
        }
      });
    }
    setDevicesInCallGroup(rows);
  };

  const handleNavigateToCallSettings = () => {
    setIsOpen(false);
    navigate(`../callsettings?inputNumber=${1}&callGroup=${callGroupNumber}`, { relative: 'path' });
    // Redirect to the call settings page
  };

  useEffect(() => {
    generateRows();
  }, [callGroupNumber, contactGroupDevices]);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg">
      <DialogTitle>{strings.title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box sx={styles.settingsWrapper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={styles.title}>
                    {strings.selectGroup} - {callGroupNumber}
                  </Box>
                  <Box>{strings.description}</Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={styles.dataGridWrapper}>
              <DataGrid rows={devicesInCallGroup} columns={columns} />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  settingsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  descriptionWrapper: {
    width: '100%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  selectWrapper: {
    width: '50%'
  },
  select: {
    width: '100%'
  },
  dataGridWrapper: {
    height: 400,
    width: '100%'
  },
  buttonWrapper: {
    marginTop: '40px'
  }
};

export default InputCallDialog;
