import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import AddDeviceManuallyDataGrid from 'features/RemoteManagement/NewSiteWizard/steps/AddDevices/components/datagrids/AddDeviceManuallyDataGrid';
import StationSearchDataGrid from 'features/RemoteManagement/NewSiteWizard/steps/AddDevices/components/datagrids/StationSearchDataGrid';
import { SearchDevice } from 'features/RemoteManagement/Types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateDeviceMutation, useLazyGetDeviceListWithSitePublicIdQuery } from 'services/aiphoneCloud';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import { buildDevicePayload } from 'shared/utils/removeManagementHelperFunctions';

interface Props {
  isAddDevicesWizardOpen: boolean;
  setIsAddDevicesWizardOpen: (isAddDevicesWizardOpen: boolean) => void;
  sitePublicId: string;
  restrictedMacAddresses: string[];
  setRestrictedMacAddresses: (restrictedMacAddresses: string[]) => void;
}

const AddDevicesWizard = ({
  isAddDevicesWizardOpen,
  setIsAddDevicesWizardOpen,
  sitePublicId,
  restrictedMacAddresses,
  setRestrictedMacAddresses
}: Props) => {
  const { t } = useTranslation();
  const [searchSelectedDevices, setSearchSelectedDevices] = useState<SearchDevice[]>([]);
  const [manualSelectedDevices, setManualSelectedDevices] = useState<GridValidRowModel[]>([]);
  const [, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingManually, setIsAddingManually] = useState(false);
  const [createDevice] = useCreateDeviceMutation();
  const devices = useSelector((state: RootState) => state.devices);
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();

  useEffect(() => {
    Object.entries(devices.DeviceList).forEach(([, device]) => {
      if (device.basicInfo.macAddress) {
        if (!restrictedMacAddresses.includes(device.basicInfo.macAddress)) {
          restrictedMacAddresses.push(device.basicInfo.macAddress);
        }
      }
    });
  }, [devices.DeviceList]);

  const handleAddDevices = async () => {
    const devicesToAdd = buildDevicePayload([...searchSelectedDevices, ...manualSelectedDevices]);

    const params = {
      sitePublicId: sitePublicId,
      devices: devicesToAdd
    };

    try {
      setIsLoading(true);
      const response = await createDevice(params);
      if (response.data) {
        fetchDevices({ sitePublicId: sitePublicId, qty: -1, page: 0 });
        setSearchSelectedDevices([]);
        setManualSelectedDevices([]);
        setIsAddDevicesWizardOpen(false);
      } else {
        setErrorMessage(response.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error adding devices:', error);
      // TODO: Add error handling
    }
  };

  return (
    <Dialog open={isAddDevicesWizardOpen} maxWidth="xl" fullWidth>
      <DialogTitle>Add Devices Wizard</DialogTitle>
      <DialogContent>
        {!isAddingManually ? (
          <StationSearchDataGrid
            searchSelectedDevices={searchSelectedDevices}
            setSearchSelectedDevices={setSearchSelectedDevices}
            setIsAddingManually={setIsAddingManually}
            restrictedMacAddresses={restrictedMacAddresses}
            setRestrictedMacAddresses={setRestrictedMacAddresses}
          />
        ) : (
          <AddDeviceManuallyDataGrid
            manualSelectedDevices={manualSelectedDevices}
            setManualSelectedDevices={setManualSelectedDevices}
            restrictedMacAddresses={restrictedMacAddresses}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!isAddingManually ? (
          <Button onClick={() => setIsAddingManually(true)} disabled={isLoading}>
            {t('Add_Manually_Button')}
          </Button>
        ) : (
          <Button onClick={() => setIsAddingManually(false)} disabled={isLoading}>
            {t('Search')}
          </Button>
        )}
        <Button onClick={() => setIsAddDevicesWizardOpen(false)}>{t('Cancel')}</Button>
        <Button
          onClick={handleAddDevices}
          disabled={isLoading || (searchSelectedDevices.length === 0 && manualSelectedDevices.length === 0)}
        >
          {t('Add_Button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDevicesWizard;
