import React from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';

interface IOptionType {
  value: number;
  label: string;
}

type CallPriorityProps = {
  setNeedSave: (value: boolean) => void;
  fieldName: string;
  value: number;
  callPriorityList: IOptionType[];
  onChange: (fieldName: string, value: string | number) => void;
};

const CallPriority = ({ setNeedSave, fieldName, value, callPriorityList, onChange }: CallPriorityProps) => {
  const handleCallPriorityChange = (event: React.SyntheticEvent<Element, Event>, option: IOptionType | null) => {
    if (option !== null) {
      setNeedSave(true);
      onChange(fieldName, option.value);
    }
  };

  return (
    <Box>
      <Autocomplete
        value={callPriorityList.find((option) => option.value === Number(value)) || null}
        defaultValue={callPriorityList[0] || 1}
        options={callPriorityList}
        getOptionLabel={(option) => option.label}
        onChange={handleCallPriorityChange}
        renderInput={(params) => <TextField {...params} label="Call Priority" />}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    </Box>
  );
};

export default CallPriority;
