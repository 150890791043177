import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Switch,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DevicesState, getSelectedDevice, IAddressBookEntry, IDevice } from 'store/slices/devicesSlice';
import DeviceCard from '../components/DeviceCard';
import AppCard from '../components/AppCard';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import AddedAppUnits from '../../callSettings/callDestination/addedAppUnits/AddedAppUnits';
import { useTranslation } from 'react-i18next';
import { IUnit, IUnitList, IUnitState } from 'store/slices/unitsSlice';
import FilterByAddedDevices from '../../Components/FilterByAddedDevices';
import AddedDevices from '../../Components/AddedDevices';

const MAX_DEVICES_FOR_TRANSFER = 10;
const DEFAULT_DELAY_TIME = 30;
const MAX_DELAY_TIME = 300;

export interface TransferSettingsInput {
  selectedDeviceIds: string[];
  selectedAppUnitId: string | null;
  enableTransfers: boolean;
  delay: number;
}

interface TransferSettingsProps {
  title: string;
  helperText: string;
  maxDelay?: number;
  showDelayField?: boolean;
  isDelayTransfer?: boolean;
  handleSave: (transferSettings: TransferSettingsInput) => Promise<void>;
}

const TransferSettings = ({
  title,
  helperText,
  showDelayField = false,
  isDelayTransfer = false,
  handleSave
}: TransferSettingsProps) => {
  const selectedDevice = useSelector(getSelectedDevice);
  const units = useSelector((state: RootState) => state.units);
  const devices = useSelector((state: RootState) => state.devices);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [delay, setDelay] = useState(
    String(isDelayTransfer ? selectedDevice?.transferSettings?.transferDelayTime || DEFAULT_DELAY_TIME : null)
  );
  const [enableTransfers, setEnableTransfers] = useState(
    isDelayTransfer ? selectedDevice.transferSettings?.transferDelay : selectedDevice.transferSettings?.transferAbsent
  );
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>(
    isDelayTransfer
      ? selectedDevice?.transferSettings?.deviceTransferDelayDestinationList || []
      : selectedDevice?.transferSettings?.deviceTransferAbsentDestinationList || []
  );
  const [selectedAppUnitId, setSelectedAppUnitId] = useState<string | null>(
    isDelayTransfer
      ? selectedDevice?.transferSettings?.deviceTransferDelayDestinationInfo || null
      : selectedDevice?.transferSettings?.deviceTransferAbsentDestinationInfo || null
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [addressBookAppUnits, setAddressBookAppUnits] = useState<IUnit[]>([]);
  const [filteredAddressBookAppUnits, setFilteredAddressBookAppUnits] = useState<IUnit[]>([]);
  const [addressBookDevices, setAddressBookDevices] = useState<IDevice[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<IDevice[]>([]);

  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const { t } = useTranslation();
  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');

  const extractAddressBookEntries = (selectedDevice: IDevice, devices: DevicesState, units: IUnitState) => {
    const addressBookDevicesList: IDevice[] = [];
    const addressBookAppUnitsList: IUnit[] = [];

    const addressBookList: IAddressBookEntry[] = selectedDevice?.systemInfo?.addressBook || [];
    addressBookList.forEach((entry) => {
      if (entry.targetDevicePublicId) {
        const deviceResult = Object.values(devices.DeviceList).find(
          (device) => device.publicId === entry.targetDevicePublicId
        );
        if (deviceResult) addressBookDevicesList.push(deviceResult);
      }
      if (entry.targetUnitPublicId) {
        const appUnitResult = Object.values(units.UnitList).find((unit) => unit.publicId === entry.targetUnitPublicId);
        if (appUnitResult) addressBookAppUnitsList.push(appUnitResult);
      }
    });
    return { addressBookDevicesList, addressBookAppUnitsList };
  };

  useEffect(() => {
    if (selectedDevice) {
      const { addressBookDevicesList, addressBookAppUnitsList } = extractAddressBookEntries(
        selectedDevice,
        devices,
        units
      );
      setAddressBookAppUnits(addressBookAppUnitsList);
      setFilteredAddressBookAppUnits(addressBookAppUnitsList);
      setAddressBookDevices(addressBookDevicesList);
      setFilteredDevices(addressBookDevicesList);
    }
  }, [selectedDevice, devices, units]);

  const handleSelectDevice = (id: string) => {
    setHasUnsavedChanges(true);
    if (isMultiSelect) {
      if (selectedDeviceIds.length < MAX_DEVICES_FOR_TRANSFER || selectedDeviceIds.includes(id)) {
        setSelectedDeviceIds((prev) =>
          prev.includes(id) ? prev.filter((deviceId) => deviceId !== id) : [...prev, id]
        );
      } else {
        setErrorMessage(t('Invalid_No_Of_Devices_For_Transfer'));
        setShowAlert(true);
      }
    } else {
      setSelectedDeviceIds([id]);
    }
  };

  const onSave = async () => {
    setIsSubmitting(true);

    const allTransferSettings: TransferSettingsInput = {
      selectedDeviceIds,
      selectedAppUnitId,
      enableTransfers,
      delay: Number(delay)
    };

    try {
      await handleSave(allTransferSettings);
      setSuccessMessage(successUpdateDevice);
      setErrorMessage(null);
      setShowAlert(true);
    } catch (error) {
      setErrorMessage(errorUpdateDevice);
      setSuccessMessage(null);
      setShowAlert(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFilterChange = (fieldName: string, query: string) => {
    if (!query) {
      setFilteredDevices(addressBookDevices);
      setFilteredAddressBookAppUnits(addressBookAppUnits);
      return;
    }

    const lowerCaseQuery = query.toLowerCase();

    switch (fieldName) {
      case 'stationName': {
        const filteredList = filteredDevices.filter((device) =>
          device.basicInfo.stationName.toLowerCase().includes(lowerCaseQuery)
        );
        setFilteredDevices(filteredList);
        break;
      }
      case 'unitNumber': {
        const unitList: IUnitList = units.UnitList;
        const matchingUnits = Object.values(unitList).filter((unit) => unit.unitNumber.includes(query));
        const matchingDeviceIds = matchingUnits.flatMap((unit) => unit.devicePublicIds);
        setFilteredDevices(addressBookDevices.filter((device) => matchingDeviceIds.includes(device.publicId)));

        const filteredAppUnits = addressBookAppUnits.filter((unit) =>
          unit.unitNumber?.toString().includes(lowerCaseQuery)
        );
        setFilteredAddressBookAppUnits(filteredAppUnits);

        break;
      }
      case 'stationNumber': {
        const filteredList = filteredDevices.filter((device) =>
          device.basicInfo.stationNumber?.toString().includes(lowerCaseQuery)
        );
        setFilteredDevices(filteredList);
        break;
      }
      default:
        setErrorMessage('Invalid filter field');
    }
  };

  const handleConfirmDialog = () => {
    setHasUnsavedChanges(true);
    setEnableTransfers(true);
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleToggle = (event) => {
    if (event.target.checked) {
      setOpenDialog(true);
    } else {
      setEnableTransfers(false);
    }
    setHasUnsavedChanges(true);
  };

  const toggleMultiSelect = () => {
    setIsMultiSelect((prev) => !prev);
    setSelectedDeviceIds(selectedDeviceIds);
    setIsSelectAll(false);
  };

  const toggleSelectAll = () => {
    if (filteredDevices.length === 0) return;
    if (filteredDevices.length > MAX_DEVICES_FOR_TRANSFER) {
      setErrorMessage(t('Invalid_No_Of_Devices_For_Transfer'));
      setShowAlert(true);
      return;
    }
    setIsSelectAll((prev) => !prev);
    if (!isSelectAll) {
      setSelectedDeviceIds(filteredDevices.map((device) => device.publicId)); // Select all devices
    } else {
      setSelectedDeviceIds([]);
    }
  };

  const handleSelectApp = (id: string) => {
    setHasUnsavedChanges(true);
    setSelectedAppUnitId(id);
  };

  const onRemoveDevice = (id: string) => {
    setHasUnsavedChanges(true);

    if (!selectedDeviceIds.includes(id)) {
      return;
    }

    const updatedDeviceIds = selectedDeviceIds.filter((deviceId) => deviceId !== id);

    setSelectedDeviceIds(updatedDeviceIds);

    if (updatedDeviceIds.length === 0) {
      setIsSelectAll(false);
    }
  };

  const handleDelayTimeChange = (event: any) => {
    const delayTimeRegex = /^(?:[1-9]|[1-9][0-9]|[1-2][0-9][0-9]|300)$/;
    const value = event.target.value;

    if (value === '') {
      // Allow the user to clear the input
      setDelay(value);
      setShowAlert(false);
      setErrorMessage('');
      return;
    }

    const numericValue = Number(value);

    if (numericValue > MAX_DELAY_TIME || numericValue < 1 || !delayTimeRegex.test(value)) {
      setErrorMessage(t('Invalid_Delay_Time'));
      setShowAlert(true);
    } else {
      setErrorMessage('');
      setShowAlert(false);
    }

    setDelay(value);
    setHasUnsavedChanges(true);
  };
  const onRemoveApp = (_id: string) => {
    setHasUnsavedChanges(true);
    setSelectedAppUnitId(null);
  };

  const handleReset = () => {
    setFilteredDevices(addressBookDevices);
    setFilteredAddressBookAppUnits(addressBookAppUnits);
    setSelectedDeviceIds(
      isDelayTransfer
        ? selectedDevice?.transferSettings?.deviceTransferDelayDestinationList || []
        : selectedDevice?.transferSettings?.deviceTransferAbsentDestinationList || []
    );
    setSelectedAppUnitId(
      isDelayTransfer
        ? selectedDevice?.transferSettings?.deviceTransferDelayDestinationInfo?.[0] || null
        : selectedDevice?.transferSettings?.deviceTransferAbsentDestinationInfo?.[0] || null
    );
    setEnableTransfers(
      isDelayTransfer ? selectedDevice.transferSettings?.transferDelay : selectedDevice.transferSettings?.transferAbsent
    );
    setDelay(String(isDelayTransfer ? selectedDevice?.transferSettings?.transferDelayTime : null));
    setHasUnsavedChanges(false);
  };

  return (
    <Grid container style={styles.transferWrapper}>
      <Dialog open={openDialog} onClose={handleConfirmDialog}>
        <DialogTitle>
          {isDelayTransfer ? t('Delay_Transfer_Dialog_Title') : t('Absent_Transfer_Dialog_Title')}
        </DialogTitle>
        <DialogContent>
          {isDelayTransfer ? t('Delay_Transfer_Dialog_Content') : t('Absent_Transfer_Dialog_Content')}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('Button_Cancel')}</Button>
          <Button onClick={handleConfirmDialog}>{t('Button_Confirm')}</Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item lg={12}>
          <Box sx={styles.controlPanelWrapper}>
            <Box sx={styles.descriptionWrapper}>
              <Box sx={styles.title}>{title}</Box>
              <Box sx={styles.description}>{t('Transfer_Page_Description')}</Box>
            </Box>
            <Box sx={styles.controlPanelWrapper}>
              <Box style={styles.buttonsWrapper}>
                <Box className="mx-2">
                  <LoadingButton
                    variant="contained"
                    type="reset"
                    disabled={isSubmitting || !hasUnsavedChanges}
                    onClick={handleReset}
                  >
                    {t('Button_Reset')}
                  </LoadingButton>
                </Box>
                <Box>
                  <LoadingButton
                    variant="outlined"
                    onClick={onSave}
                    loading={isSubmitting}
                    type="submit"
                    disabled={isSubmitting || !hasUnsavedChanges}
                  >
                    {t('Button_Save_Changes')}
                  </LoadingButton>
                  <SnackbarAlert
                    type={errorMessage ? 'error' : 'success'}
                    time={errorMessage ? 10000 : 3000}
                    text={errorMessage || successMessage || ''}
                    isOpen={showAlert}
                    onClose={() => setShowAlert(false)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid container sx={styles.deviceSettingsWrapper}>
          <Grid item xs={2} sm={2} md={2} lg={2} style={styles.filterWrapper}>
            <FilterByAddedDevices filterDevices={handleFilterChange} />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} sx={styles.addressBookItemsWrapper}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={styles.transferSettingsWrapper}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="h6">
                        {isDelayTransfer ? t('Delay_Transfer_Dialog_Title') : t('Absent_Transfer_Dialog_Title')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        checked={enableTransfers}
                        onChange={handleToggle}
                        inputProps={{ 'aria-label': 'Toggle Transfer' }}
                      />
                    </Grid>
                    {showDelayField && (
                      <Grid item>
                        <TextField
                          label="Delay"
                          type="number"
                          value={delay}
                          onChange={handleDelayTimeChange}
                          InputProps={{
                            inputProps: {
                              min: 1,
                              max: MAX_DELAY_TIME
                            }
                          }}
                          variant="outlined"
                          helperText={helperText}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
              {selectedDevice?.basicInfo?.deviceType === 16 && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <AddedAppUnits
                    appUnits={filteredAddressBookAppUnits}
                    callDestinationAppUnit={[]}
                    onSelectAppUnit={handleSelectApp}
                    handleRemoveAppUnit={() => console.log('handleRemoveAppUnit')}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AddedDevices
                  devices={filteredDevices}
                  isSelectAll={isSelectAll}
                  isMultiselect={isMultiSelect}
                  selectedDevicesIds={selectedDeviceIds}
                  onSelectDevice={handleSelectDevice}
                  toggleMultiselect={toggleMultiSelect}
                  toggleSelectAll={toggleSelectAll}
                  showDeleteButton={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={styles.addedDevicesWrapper}>
            <Grid container spacing={2}>
              {selectedDevice?.basicInfo?.deviceType === 16 && selectedAppUnitId && (
                <Grid item xs={6} sm={6} md={6}>
                  <AppCard
                    appUnit={Object.values(units.UnitList).find((unit) => unit.publicId === selectedAppUnitId)}
                    showRemoveIcon={true}
                    onRemove={onRemoveApp}
                  />
                </Grid>
              )}
              {selectedDeviceIds
                .filter((id) => Object.keys(devices.DeviceList).includes(id))
                .map((deviceKey, index) => (
                  <Grid item xs={6} sm={6} md={6} key={index}>
                    <DeviceCard devicePublicId={deviceKey} showRemoveIcon={true} onRemove={onRemoveDevice} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  controlPanelWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px'
  },
  descriptionWrapper: {
    width: '70%'
  },
  buttonsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end'
  },
  deviceSettingsWrapper: {
    display: 'flex',
    width: '100%',
    minWidth: '500px',
    height: '100%',
    backgroundColor: '#FFFFFF',
    padding: '16px'
  },
  addressBookItemsWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: '#EFEFEF'
  },
  addedDevicesWrapper: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 3fr))',
    padding: '10px'
  },
  transferWrapper: {
    width: '100%',
    minWidth: '1250px',
    height: '90%',
    zIndex: 0
  },
  delayTransferWrapper: {
    width: '100%',
    minWidth: '1250px',
    height: '90%',
    zIndex: 0
  },
  transferSettingsWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: '#EFEFEF',
    padding: '16px'
  },
  devicesBoundary: {
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#fafafa',
    border: '1px solid #e0e0e0',
    width: '100%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  filterWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  description: {}
};

export default TransferSettings;
