import { GridEditInputCell, GridRenderEditCellParams } from '@mui/x-data-grid';
import React, { forwardRef } from 'react';
import 'styles/frontshine.css';

export interface TooltipChildProps extends GridRenderEditCellParams {
  error: boolean;
  children?: React.ReactNode;
  childPosition?: 'above' | 'below';
  divProps?: React.HTMLAttributes<HTMLDivElement>;
}

/**
 * Solution for issue with MUI GridEditInputCell not properly forwarding ref
 *
 * This is a workaround based on https://github.com/mui/material-ui/issues/33476#issuecomment-1574005080
 */
export const TooltipChild = forwardRef<HTMLInputElement, TooltipChildProps>(
  ({ id, divProps, children, childPosition, ...props }, ref) => {
    const { ...other } = props;
    return (
      <div {...other} {...divProps} id={id?.toString()} ref={ref}>
        {children && childPosition === 'above' && children}
        <GridEditInputCell {...props} id={id} />
        {children && childPosition === 'below' && children}
      </div>
    );
  }
);
