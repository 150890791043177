/* eslint-disable unused-imports/no-unused-vars */
/**
 * Sync functionality, Firmware functionality for the site dashboard.
 */
import React from 'react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { clearDevices, IDevice, setLastSyncedOn, setSelectedDevice } from 'store/slices/devicesSlice';
import { getSite } from 'store/slices/siteSlice';
import CONFIG from 'config';
import {
  Alert,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Tooltip,
  CircularProgress,
  Chip,
  Card,
  CardActions,
  Theme
} from '@mui/material';
import CloudFilledIcon from '@mui/icons-material/Cloud';
import { PhoneIphone, Memory, DeviceHub, Cached } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import UpdateIcon from '@mui/icons-material/Update';
import SyncIcon from '@mui/icons-material/Sync';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { getColorFromStatus, Status } from 'shared/utils/Status';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { getString } from 'shared/utils/LocalizationUtils';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import StringUtils from 'shared/utils/StringUtils';
import { checkCurrentFirmwareStatus, checkFirmwareSeries } from 'shared/utils/firmwareFunctions';
import {
  useCreateConfigFileMutation,
  useUpdateDeviceMutation,
  useFetchCustomSoundsMutation,
  useHandleGatewayCommandMutation,
  useUpdateSyncedDateMutation,
  useGetDeviceListWithSitePublicIdQuery,
  useGetSiteWithPublicIdQuery
} from 'services/aiphoneCloud';
import { useNavigate, useParams } from 'react-router-dom';
import LoginPanel from '../../Billing/components/MobileService/LoginPanel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import { getAllSoundsBySiteId } from 'shared/api/Aws/RemoteManagementApi';
import { IRingtone } from 'features/RemoteManagement/DeviceDashboard/callSettings/outboundCallSetting/settings/RingBackTone';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useTranslation } from 'react-i18next';

const firmwareUrl = 'https://081gx2j0nf.execute-api.us-east-1.amazonaws.com/prod/firmware/download/';
const latestFirmwareUrl = 'https://081gx2j0nf.execute-api.us-east-1.amazonaws.com/prod/firmware/latest';
const ORANGE_COLOR = '#EF6C00';
const BLUE_COLOR = '#0071CE';
const GRAY_COLOR = '#E0E0EO';
const BLACK_COLOR = '#000000';
const NERO_COLOR = '#4a4a4a';

interface RowElem {
  id: string;
  MacAddress: string;
  StationNumber: string;
  StationName: string;
  ModelNumber: string | null;
  FirmwareStatus: string;
  Status: string;
  ConfigFileUrl: string;
}

const Site = () => {
  const [isGWFirmwareDialogOpen, setGWFirmwareDialogOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateSyncedDate] = useUpdateSyncedDateMutation();
  const site = useSelector(getSite);
  const sitePublicId = useParams().id || '';
  const { isLoading } = useGetDeviceListWithSitePublicIdQuery({ sitePublicId: sitePublicId, qty: '-1', page: 1 });
  const { isLoading: isSiteLoading } = useGetSiteWithPublicIdQuery(sitePublicId);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const { DeviceList: devices } = useSelector((state: RootState) => state.devices);

  const hostname = window.location.hostname;
  const isProductionAccount = !['preprod', 'beta', 'localhost'].some((substring) => hostname.includes(substring));

  const [gwOnlineStatus, setGwOnlineStatus] = useState(Status.Online);
  const [isGWRegistered, setIsGatewayRegistered] = useState(true);
  const [isGatewayUnregistered, setIsGatewayUnregistered] = useState(false);
  const [syncStatus, setSyncStatus] = useState(Status.StandBy);
  const [latestFirmwareList, setLatestFirmwareList] = useState<any>({});
  const [rows, setRows] = useState<RowElem[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [createConfigFile] = useCreateConfigFileMutation();
  const [updateDevice] = useUpdateDeviceMutation();
  const [fetchCustomSoundsById] = useFetchCustomSoundsMutation();

  // list of all App Groups by unitPublicId
  const appGroups = useSelector((state: RootState) => state.apps.AppGroup);
  const appList = useSelector((state: RootState) => state.apps.AppList);
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const tenantList = useSelector((state: RootState) => state.tenants.TenantList); // list of all tenants by unitPublicId
  const users = useSelector((state: RootState) => state.users);

  //Sync sound files variables
  const [ringtoneList, setRingtoneList] = useState<IRingtone[]>([]);
  const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>({});
  const [acltoken, setAcltoken] = useState<string | null>(null);

  const { isAllowedTo } = usePermission();
  const hasEditPermission = isAllowedTo('site:edit', site.siteInfo.publicId, PermissionsContextType.SITE);

  /** Setting up the possible gateway credentials */
  const possibleGwCredentials = [
    {
      gwId: gateway?.basicInfo?.memoId,
      gwPassword: gateway?.basicInfo?.memoPass
    },
    {
      gwId: gateway?.basicInfo?.adminId,
      gwPassword: gateway?.basicInfo?.adminPass
    },
    {
      gwId: 'admin',
      gwPassword: 'admin'
    }
  ];

  const unitIds: string[] = [];
  const tenantIds: string[] = [];
  const roomIds: number[] = [];
  const unitsList = Object.values(unitList);
  const tenantsWithPhoneNumbers = Object.values(tenantList);

  if (Array.isArray(unitsList)) {
    for (let i = 0, len = unitsList.length; i < len; i++) {
      if (unitsList[i].appPublicIds !== null) {
        unitIds.push(unitsList[i].publicId);
        roomIds.push(unitsList[i].unitSequentialNumber);
      }
    }
  }
  if (Array.isArray(tenantsWithPhoneNumbers)) {
    for (let i = 0, len = tenantsWithPhoneNumbers.length; i < len; i++) {
      if (tenantsWithPhoneNumbers[i].phoneNumber !== null) {
        tenantIds.push(tenantsWithPhoneNumbers[i].unitPublicId);
      }
    }
  }

  // isFirstSyncToIXGCloud is true when we load the page for the first time
  const [isFirstSyncToIXGCloud, setIsFirstSyncToIXGCloud] = useState(true);
  const [showIXGSync, setShowIXGSync] = useState(false);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);

  const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
  const [syncDialogTitle, setSyncDialogTitle] = useState('');
  const [syncDialogContent, setSyncDialogContent] = useState('');

  const handleClose = () => {
    setIsLoginDialogOpen(false);
    setIsSyncDialogOpen(false);
  };

  const setUsernameAndToken = useCallback(
    async (userName: string, receivedToken: string) => {
      localStorage.setItem('acltoken', receivedToken);
      setAcltoken(receivedToken);
      localStorage.setItem('aclUserName', userName);
      setTimeout(() => {
        //close the dialog after 3 second from login
        setIsLoginDialogOpen(false);
      }, 3000);
    },
    [dispatch]
  );

  const getSerialNumber = (macAddress: string) => {
    const FNV_PRIME_64 = BigInt('0x100000001b3');
    const FNV_OFFSET_BASIS_64 = BigInt('14695981039346656037');

    let hash = FNV_OFFSET_BASIS_64;

    for (let i = 0; i < macAddress.length; i++) {
      hash ^= BigInt(macAddress.charCodeAt(i));
      hash = (hash * FNV_PRIME_64) % BigInt('0x10000000000000000'); // Ensure 32-bit overflow
    }

    return hash.toString(16); // Return hash as hexadecimal string
  };

  const [handleGatewayCommand, { isLoading: isGwProcessingCommand }] = useHandleGatewayCommandMutation();

  const isGatewayFirstSync = gateway?.lastSyncedOn === null;

  useEffect(() => {
    (async () => {
      const checkLatestFirmware = async () => {
        const firmwareVersionCheck = await fetch(latestFirmwareUrl);
        const latestVersion = await firmwareVersionCheck.json();
        setLatestFirmwareList(latestVersion);
      };
      await checkLatestFirmware();
    })();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('acltoken');
    setAcltoken(token);
    dispatch(clearDevices());
  }, []);

  useEffect(() => {
    (async () => {
      const deviceRows = buildDeviceRow(Object.values(devices));
      const sortedRows = deviceRows.sort((a, b) => {
        if (a.ModelNumber === 'IXGW-GW' && b.ModelNumber !== 'IXGW-GW') {
          return -1;
        } else if (a.ModelNumber !== 'IXGW-GW' && b.ModelNumber === 'IXGW-GW') {
          return 1;
        } else {
          return 0;
        }
      });

      setRows(sortedRows);

      if (Object.keys(devices).length === 0) {
        // Set the gateway registration state to false because there are no devices.
        setIsGatewayRegistered(false);
      } else if (Object.keys(devices).length > 0) {
        setIsGatewayRegistered(gateway !== undefined);
        setIsGatewayUnregistered(true);
      }
    })();
  }, [devices, site]);

  useEffect(() => {
    /** Retrieving all audio files to generate the link for sending to the gateway. */
    if (site.siteInfo?.publicId && users.currentUser?.publicId) {
      const getAllSoundsPayload = {
        siteId: site.siteInfo.publicId,
        userId: users.currentUser?.publicId
      };
      (async () => {
        const allSounds = (await getAllSoundsBySiteId(getAllSoundsPayload)) as IRingtone[];
        if (allSounds?.length > 0) {
          setRingtoneList(allSounds);
        }
      })();
    }
  }, []);

  const handleNavigateToFirmwareUpdate = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/maintenance`);
  };

  const handleNavigateToStationInfo = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/stationinfo`);
  };

  const handleNavigateToNetworkSettings = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/networkinfo`);
  };

  const handleNavigateToUnit = () => {
    navigate(`/site/${site.siteInfo.publicId}/units`);
  };

  const handleNavigateToCallList = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/callsettings`);
  };

  const handleNavigateToAddressBook = (rowData: RowElem) => {
    const deviceId = rowData.id;
    dispatch(setSelectedDevice(deviceId));
    navigate(`/site/${site.siteInfo.publicId}/devices/${deviceId}/addressbook`);
  };

  const handleOpenLoginDialog = () => {
    setIsLoginDialogOpen(true);
  };

  // return the device status based on the device's properties
  const getDeviceStatus = (device: IDevice) => {
    if (!device.networkSettings?.ipV4Address || device.networkSettings?.ipV4Address === '0.0.0.0') {
      return Status.NeedsAssociation;
    } else if (
      device.unitPublicId === null &&
      device.basicInfo.deviceType !== 6 &&
      device.basicInfo.deviceType !== 17 &&
      device.basicInfo.deviceType !== 18
    ) {
      return Status.NeedsUnit;
    } else if (device.basicInfo?.stationNumber === '') {
      return Status.NeedsStationNumber;
    } else if (
      (device.basicInfo.deviceType === 4 ||
        device.basicInfo.deviceType === 14 ||
        device.basicInfo.deviceType === 15 ||
        device.basicInfo.deviceType === 16) &&
      device.systemInfo?.addressBook?.[0]?.targetDevicePublicId === undefined &&
      device.systemInfo?.addressBook?.[0]?.targetUnitPublicId === undefined
    ) {
      return Status.NeedsAddressBook;
    } else if (
      (device.basicInfo.deviceType === 5 ||
        device.basicInfo.deviceType === 8 ||
        device.basicInfo.deviceType === 9 ||
        device.basicInfo.deviceType === 10 ||
        device.basicInfo.deviceType === 11 ||
        device.basicInfo.deviceType === 12 ||
        device.basicInfo.deviceType === 20) &&
      device?.callSettings?.contactGroupList[1].targetList?.[0]?.targetDevicePublicId === undefined &&
      device?.callSettings?.contactGroupList[1].targetList?.[0]?.targetUnitPublicId === undefined
    ) {
      return Status.NeedsCallList;
    } else if (
      device.lastSyncedOn === null ||
      device.lastUpdatedOn === null ||
      device.lastSyncedOn < device.lastUpdatedOn
    ) {
      return Status.NeedsSync;
    } else {
      return Status.StandBy;
    }
  };

  const sendCommandAndFetchResult = async (credentials) => {
    if (!gateway) {
      return null;
    }

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: credentials.gwId,
      gwPassword: credentials.gwPassword,
      gwIpAddress: gateway?.networkSettings?.ipV4Address || ''
    };

    try {
      // send command to gateway via ioT
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
      if (ioTPayload === 'Missing information' || gateway?.basicInfo?.macAddress === undefined) {
        throw new Error('Missing information');
      }
      await handleGatewayCommand(ioTPayload).unwrap();

      await new Promise((resolve) => setTimeout(resolve, 10000));

      // fetch the result from the gateway
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      return fetchResponse;
    } catch (error) {
      console.error('Error with credentials:', credentials, error);
      return null;
    }
  };

  /** Part of the Gateway Status check logic */
  const checkStatusWithStationSearch = async () => {
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    let fetchResponse;
    let statusCode;

    for (const credentials of possibleGwCredentials) {
      fetchResponse = await sendCommandAndFetchResult(credentials);
      if (fetchResponse) {
        statusCode = fetchResponse?.statusCode.slice(0, 3);
        if (statusCode === '200') {
          break;
        } else if (statusCode === '406') {
          setGwOnlineStatus(Status.Busy);
          return;
        } else if (statusCode === '402') {
          continue;
        }
      }
    }
    setIsSyncDialogOpen(false);

    if (statusCode === '200') {
      setGwOnlineStatus(Status.Online);
      // Process results from Search
      // Check the payload for the Gateway's firmware version and IP address
      const gatewayInfoFromResponse = fetchResponse.payload.find(
        (device: { mac_addr: string }) => device.mac_addr === gateway?.basicInfo.macAddress
      );
      const gwFirmwareVersion = gatewayInfoFromResponse.fw_ver;
      const gwIPAddress = gatewayInfoFromResponse.ip_addr;

      // compare the results to what we have saved for the gateway
      if (gateway?.basicInfo?.firmwareVersion === null || gwFirmwareVersion !== gateway?.basicInfo?.firmwareVersion) {
        const updatedDeviceParams = {
          device: {
            publicId: site.siteInfo?.registeredGatewayPublicId,
            basicInfo: {
              firmwareVersion: gwFirmwareVersion
            }
          }
        };
        dispatch(updateDevice(updatedDeviceParams));
      }
      if (gwIPAddress !== gateway?.networkSettings?.ipV4Address) {
        const updatedDeviceParams = {
          device: {
            publicId: site.siteInfo?.registeredGatewayPublicId,
            networkSettings: {
              ipV4Address: gwIPAddress
            }
          }
        };
        dispatch(updateDevice(updatedDeviceParams));
      }
      // loop through the other devices in the site
      for (const device of Object.values(devices)) {
        const deviceInfo = {
          deviceIpAddress: device?.networkSettings?.ipV4Address,
          deviceMacAddress: device?.basicInfo.macAddress,
          deviceStationNumber: device?.basicInfo.stationNumber,
          deviceId: systemId ? systemId : device?.basicInfo.adminId,
          devicePassword: systemPassword ? systemPassword : device?.basicInfo.adminPass
        };
        const deviceInfoFromResponse = fetchResponse.payload.find(
          (device: { mac_addr: string }) => device.mac_addr === deviceInfo.deviceMacAddress
        );
        if (deviceInfoFromResponse === undefined) {
          continue;
        }
        // check firmware info
        const deviceFirmwareVersion = deviceInfoFromResponse.fw_ver;
      }
    }
  };

  /** Checking the gateway status */
  const getGatewayOnlineStatus = async () => {
    if (gwOnlineStatus === Status.Busy || gwOnlineStatus === Status.Syncing || gwOnlineStatus === Status.Updating) {
      return;
    }
    setGwOnlineStatus(Status.Checking);
    const dialogTitle = t('Gateway_StationSearch_Title');
    const dialogContent = t('Gateway_StationSearch_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    await checkStatusWithStationSearch();
  };

  // ************************************ Device Check ************************************
  const handleDeviceCheckButton = async () => {
    if (gwOnlineStatus === Status.Checking) {
      return;
    }
    const dialogTitle = t('Gateway_DeviceCheck_Title');
    const dialogContent = t('Gateway_DeviceCheck_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Checking);

    setRows(rows.map((row) => ({ ...row, Status: t('Checking') })));

    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };

    for (const device of rows) {
      const deviceType = device?.ModelNumber;
      // skip adaptors
      if (
        deviceType === 'IXGW-GW' ||
        deviceType === 'IXGW-TGW' ||
        deviceType === 'IXW-MA' ||
        deviceType === 'IXW-MAA' ||
        deviceType === 'IXW-MAA-SOFT' ||
        deviceType === 'IXW-MAA-SOFT-5' ||
        deviceType === 'IXW-MAA-SOFT-10'
      ) {
        continue;
      }
      const selectedDevice = devices[device.id];
      const isDeviceFirstSync = selectedDevice.lastSyncedOn === null;

      const deviceInfo = {
        deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
        deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
        deviceType: deviceType,
        deviceId: systemId ? systemId : isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
        devicePassword: systemPassword
          ? systemPassword
          : isDeviceFirstSync
          ? 'admin'
          : selectedDevice?.basicInfo.adminPass
      };
      try {
        // send command to gateway via ioT
        const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.DEVICE_CHECK, gatewayInfo, deviceInfo, null);
        if (ioTPayload === 'Missing information') {
          throw new Error('Missing information');
        }
        await handleGatewayCommand(ioTPayload).unwrap();

        // wait 15 seconds
        await new Promise((resolve) => setTimeout(resolve, 15000));

        // fetch the result from the gateway
        const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.DEVICE_CHECK, gatewayInfo, deviceInfo, null);
        const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
        const statusCode = fetchResponse?.payload[0].statusCode.slice(0, 3);
        if (statusCode === '200') {
          device.Status = Status.Success;
          setRows(rows);
        } else {
          device.Status = Status.Error;
          const dialogTitle = t('Error_for_device_with_station_number', {
            deviceType,
            stationNumber: selectedDevice?.basicInfo?.stationNumber
          });
          const dialogContent = t('Error_message', {
            statusCode: fetchResponse?.payload[0].statusCode,
            errorMessage: fetchResponse?.payload[0][0]
          });
          setErrorMessage(
            t('Error_message_for_device_with_station_number', {
              errorMessage: dialogContent,
              deviceType,
              stationNumber: selectedDevice?.basicInfo?.stationNumber
            })
          );
          setSyncDialogTitle(dialogTitle);
          setSyncDialogContent(dialogContent);
          setRows(rows);
        }
      } catch (error) {
        device.Status = Status.Error;
        setRows(rows);
      }
    }
    setIsSyncDialogOpen(false);
    setGwOnlineStatus(Status.Online);
  };

  /****************************************   FIRMWARE UPDATE  ****************************************/
  const handleSyncFirmware = async () => {
    setRows(rows.map((row) => ({ ...row, Status: 'Checking' })));
    const dialogTitle = t('Gateway_DeviceFirmwareUpdate_Title');
    const dialogContent = t('Gateway_DeviceFirmwareUpdate_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Checking);

    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : gateway?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : gateway?.basicInfo.adminPass
    };

    for (const device of rows) {
      const deviceType = device?.ModelNumber;
      if (deviceType === 'IXGW-GW' || deviceType === null) {
        continue;
      }
      // find selected device for firmware
      const deviceFirmwareVersion = devices[device.id]?.basicInfo?.firmwareVersion;
      const latestFirmwareName = latestFirmwareList[deviceType]?.standard?.name;

      // TODO: check to see if the device's firmware is enhanced, and check that version instead
      if (
        deviceType in latestFirmwareList &&
        Object.keys(latestFirmwareList[deviceType]).includes('standard') &&
        /\d\.\d\d/.test(deviceFirmwareVersion) &&
        latestFirmwareList[deviceType].standard.version > parseInt(deviceFirmwareVersion.replace('.', ''))
      ) {
        const selectedDevice = devices[device.id];
        const deviceFirmwareUrl = firmwareUrl + latestFirmwareList[deviceType].standard.name;
        const downloadResponse = await fetch(deviceFirmwareUrl);
        const isDeviceFirstSync = selectedDevice?.lastSyncedOn === null;

        const url = await downloadResponse.text();

        const deviceInfo = {
          deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
          deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
          deviceType: selectedDevice?.basicInfo.deviceType,
          deviceId: systemId ? systemId : isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
          devicePassword: systemPassword
            ? systemPassword
            : isDeviceFirstSync
            ? 'admin'
            : selectedDevice?.basicInfo.adminPass,
          deviceFirmwareFileName: latestFirmwareName,
          deviceFirmwareLink: url
        };
        try {
          const ioTPayload = fetchGatewayCommand(
            'sendCommand',
            gwCommand.FIRMWARE_UPDATE,
            gatewayInfo,
            deviceInfo,
            null
          );
          device.Status = Status.Updating;
          setRows(rows);
          await handleGatewayCommand(ioTPayload).unwrap();

          // wait 5 minutes
          // TODO: improve this to check the status of the firmware update, especially if there is an early failure response
          await new Promise((resolve) => setTimeout(resolve, 300000));

          const fetchPayload = fetchGatewayCommand(
            'fetchResult',
            gwCommand.FIRMWARE_UPDATE,
            gatewayInfo,
            deviceInfo,
            null
          );
          let fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

          if (fetchResponse?.statusCode.includes('206')) {
            fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
          }

          // Check the payload for the ip address to see if firmware update was successful
          if (fetchResponse?.payload[0].statusCode.includes('200')) {
            setShowAlert(true);
            // update the device firmware version in redux and the database
            const fwVersion = Number(latestFirmwareList[deviceType].standard.version / 100).toFixed(2);
            const updateDevicePayload = {
              ...selectedDevice,
              basicInfo: {
                ...selectedDevice.basicInfo,
                firmwareVersion: fwVersion
              }
            };
            await updateDevice(updateDevicePayload);
            device.Status = Status.Success;
            setRows(rows);
          } else {
            device.Status = Status.Error;
            setRows(rows);
            setErrorMessage(t('Failed_to_update_firmware_for_device'));
          }
        } catch (error) {
          setErrorMessage(t('Failed_to_update_firmware_for_device'));
        }
      } else {
        device.Status = Status.UpToDate;
        setRows(rows);
      }
      setIsSyncDialogOpen(false);
    }
  };

  /****************************************   SYNC   ****************************************/

  const handleConfigFileUpload = async (device: RowElem) => {
    /** throw error if the device config file is missing */
    if (device.ConfigFileUrl === '-') {
      setErrorMessage('Config file is missing');
      return;
    }
    const selectedDevice = devices[device.id];
    if (!selectedDevice) {
      setErrorMessage('Device not found');
      return;
    }
    if (!gateway) {
      setErrorMessage('Gateway not found');
      return;
    }
    const deviceType = device.ModelNumber;
    let ioTPayload, fetchPayload, fetchResponse;
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    const memoId = gateway?.basicInfo?.memoId || 'admin';
    const memoPassword = gateway?.basicInfo?.memoPass || 'admin';

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ?? (isGatewayFirstSync ? memoId : gateway?.basicInfo.adminId),
      gwPassword: systemPassword ?? (isGatewayFirstSync ? memoPassword : gateway?.basicInfo.adminPass),
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };
    try {
      const useSiteId = site.siteInfo.systemId !== null;
      const isDeviceFirstSync = selectedDevice?.lastSyncedOn === null;
      const deviceMemoId = selectedDevice?.basicInfo.memoId || 'admin';
      const deviceMemoPass = selectedDevice?.basicInfo.memoPass || 'admin';
      let whichId = isDeviceFirstSync ? deviceMemoId : selectedDevice?.basicInfo.adminId;
      let whichPass = isDeviceFirstSync ? deviceMemoPass : selectedDevice?.basicInfo.adminPass;
      if (useSiteId) {
        whichId = site.siteInfo.systemId; // TODO: see if this matches the device's adminId in an Existing System
        whichPass = site.siteInfo.systemPassword; // TODO: see if this matches the device's adminPass in an Existing System
      }
      let deviceInfo = {
        deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
        deviceMacAddress: device.MacAddress,
        deviceStationNumber: device.StationNumber,
        deviceId: whichId,
        devicePassword: whichPass,
        deviceConfigFileUrl: device.ConfigFileUrl,
        deviceType,
        deviceFileName: `config-${device.id}.txt`
      };
      if (!isGwProcessingCommand && gwOnlineStatus === Status.Online) {
        ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
        if (ioTPayload === 'Missing information') {
          throw new Error('Missing information');
        }
        await handleGatewayCommand(ioTPayload).unwrap();

        // if this is the device's first sync, wait 2 minutes, else wait 30 seconds
        const waitTime = isDeviceFirstSync ? 120000 : 30000;
        await new Promise((resolve) => setTimeout(resolve, waitTime));

        fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
        fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

        if (fetchResponse?.statusCode.includes('206')) {
          fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
        }

        if (fetchResponse?.payload[0].statusCode.includes('200') && fetchResponse?.payload[0].message === 'Success') {
          const params = {
            device: {
              publicId: selectedDevice.publicId
            }
          };
          updateSyncedDate(params);
          dispatch(setLastSyncedOn(params.device.publicId));
          return Status.Synced;
        } else if (fetchResponse?.payload[0].statusCode.includes('402')) {
          // if we get 402, its possible something happened when we synced the device, and we should be suspicious of isDevice being incorrect
          const memoId = gateway?.basicInfo?.memoId || 'admin';
          const memoPassword = gateway?.basicInfo?.memoPass || 'admin';
          const gatewayInfo = {
            awsPropertyId: site?.siteInfo?.awsPropertyId,
            gwMacAddress: gateway?.basicInfo?.macAddress,
            gwId: systemId ?? (isGatewayFirstSync ? memoId : gateway?.basicInfo?.adminId),
            gwPassword: systemPassword ?? (isGatewayFirstSync ? memoPassword : gateway?.basicInfo?.adminPass),
            gwIpAddress: gateway?.networkSettings?.ipV4Address
          };
          const deviceMemoId = selectedDevice?.basicInfo.memoId || 'admin';
          const deviceMemoPass = selectedDevice?.basicInfo.memoPass || 'admin';
          deviceInfo = {
            deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
            deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
            deviceStationNumber: selectedDevice?.basicInfo.stationNumber,
            deviceType: deviceType,
            deviceId: systemId ?? (isDeviceFirstSync ? selectedDevice?.basicInfo.adminId : deviceMemoId),
            devicePassword:
              systemPassword ?? (isDeviceFirstSync ? selectedDevice?.basicInfo.adminPass : deviceMemoPass),
            deviceConfigFileUrl: device.ConfigFileUrl,
            deviceFileName: `config-${device.id}.txt`
          };
          const tryDefaultAdminIDandPW = fetchGatewayCommand(
            'sendCommand',
            gwCommand.SYNC,
            gatewayInfo,
            deviceInfo,
            null
          );
          let fetchResponse = await handleGatewayCommand(tryDefaultAdminIDandPW).unwrap();

          // if this is the device's first sync, wait 2 minutes, else wait 30 seconds
          const waitTime = isDeviceFirstSync ? 30000 : 120000; // opposite if first attempt, in case it's the opposite of what we think
          await new Promise((resolve) => setTimeout(resolve, waitTime));

          // fetch the result from the gateway
          const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
          fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

          if (fetchResponse?.statusCode.includes('206')) {
            fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
          } else if (fetchResponse?.statusCode.includes('402')) {
            // Gateway id and pw were wrong
            const gatewayInfo = {
              awsPropertyId: site?.siteInfo?.awsPropertyId,
              gwMacAddress: gateway?.basicInfo?.macAddress,
              gwId: systemId ?? (isGatewayFirstSync ? gateway?.basicInfo.adminId : 'admin'),
              gwPassword: systemPassword ?? (isGatewayFirstSync ? gateway?.basicInfo.adminPass : 'admin'),
              gwIpAddress: gateway?.networkSettings?.ipV4Address
            };
            const tryDefaultAdminIDandPW = fetchGatewayCommand(
              'sendCommand',
              gwCommand.SYNC,
              gatewayInfo,
              deviceInfo,
              null
            );
            let fetchResponse = await handleGatewayCommand(tryDefaultAdminIDandPW).unwrap();

            // if this is the device's first sync, wait 2 minutes, else wait 30 seconds
            const waitTime = isDeviceFirstSync ? 30000 : 120000; // opposite if first attempt, in case it's the opposite of what we think
            await new Promise((resolve) => setTimeout(resolve, waitTime));

            // fetch the result from the gateway
            const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
            fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
          }

          const statusCode = fetchResponse?.payload[0].statusCode.slice(0, 3);
          // It means the Device was synced but we missed it, maybe due to lambda timeout or page refresh by user. So we need to update the Device's lastSyncedOn date
          if (statusCode === '200') {
            const params = {
              device: {
                publicId: selectedDevice?.publicId
              }
            };
            updateSyncedDate(params);
            dispatch(setLastSyncedOn(params.device.publicId));
            device.Status = Status.Synced;
            setRows(rows);
            return;
          } else {
            return Status.Error;
          }
        } else {
          if (fetchResponse?.payload[0]?.message) {
            setErrorMessage(t('Failed_to_sync_with_IXG_Cloud') + fetchResponse?.payload[0]?.message);
          } else {
            setErrorMessage(t('Failed_to_sync_with_IXG_Cloud'));
          }
          return Status.Error;
        }
      }
    } catch (error) {
      setErrorMessage(t('Failed_to_sync_with_IXG_Cloud') + error);
      return Status.Error;
    }
  };

  /***************************                 Sound File                 ****************************/
  const generateSoundFilePayload = async (rowData: RowElem, command: string) => {
    const device = devices[rowData.id];

    if (!device) {
      return;
    }
    const params = {
      device: {
        devicePublicId: rowData.id
      }
    };

    let customSoundList;
    try {
      customSoundList = await fetchCustomSoundsById(params).unwrap();
      if (customSoundList === null) {
        return;
      }
    } catch (error) {
      console.error('Failed to fetch custom sounds:', error);
      return;
    }
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    const isDeviceFirstSync = device?.lastSyncedOn === null;

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };

    const deviceInfo = {
      deviceIpAddress: device?.networkSettings?.ipV4Address,
      deviceMacAddress: device?.basicInfo?.macAddress,
      deviceType: rowData?.ModelNumber,
      deviceId: isDeviceFirstSync ? 'admin' : device?.basicInfo.adminId,
      devicePassword: isDeviceFirstSync ? 'admin' : device?.basicInfo.adminPass,
      deviceConfigFileUrl: rowData.ConfigFileUrl,
      deviceFileName: `config-${device.publicId}.txt`,
      customSoundList: customSoundList
    };
    if (command === 'fetchResult') {
      return fetchGatewayCommand('fetchResult', gwCommand.SOUND_UPLOAD, gatewayInfo, deviceInfo, null);
    } else {
      return fetchGatewayCommand('sendCommand', gwCommand.SOUND_UPLOAD, gatewayInfo, deviceInfo, null);
    }
  };

  const handleUploadSoundFiles = async (rowData: RowElem) => {
    const device = devices[rowData.id];

    // get config file url
    try {
      setLoadingRows((prev) => ({ ...prev, [rowData.id]: true }));
      const response = await createConfigFile({
        sitePublicId: site.siteInfo.publicId,
        devicePublicId: rowData.id
      });
      if (response?.data) {
        rowData.ConfigFileUrl = response.data;

        //generate payload for sound files
        const sentPayload = await generateSoundFilePayload(rowData, 'sendCommand');
        if (!sentPayload) {
          throw new Error('Failed to upload sound files');
        }

        /*******   check to see if the device have initial synced, if not, call sync function first       ********/
        const isInitialSyncComplete = device?.lastSyncedOn !== null;
        if (!isInitialSyncComplete) {
          const syncResponse = await handleConfigFileUpload(rowData);
          if (syncResponse !== Status.Synced) {
            throw new Error('Failed to sync with the device');
          }
        }

        //send payload to gateway
        const ioTResponse = await handleGatewayCommand(sentPayload).unwrap();
        if (ioTResponse?.statusCode === 200) {
          const fetchPayload = await generateSoundFilePayload(rowData, 'fetchResult');
          let fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
          if (fetchResponse?.statusCode.includes('206')) {
            fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
          }
          if (!fetchResponse?.payload[0].statusCode.includes('200')) {
            throw new Error('Failed to upload sound files');
          }
        } else {
          throw new Error('Failed to upload sound files');
        }
      }
      setLoadingRows((prev) => ({ ...prev, [rowData.id]: false }));
      setShowAlert(true);
    } catch (error) {
      setErrorMessage(t('Failed_to_upload_sound_file'));
      setLoadingRows((prev) => ({ ...prev, [rowData.id]: false }));
    }
  };

  const handleSyncStationsButton = async () => {
    // Fetch list of config files
    // set the gateway's row as busy
    const dialogTitle = t('Gateway_DeviceSync_Title');
    const dialogContent = t('Gateway_DeviceSync_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Busy);
    setSyncStatus(Status.Waiting);
    try {
      const newRows = JSON.parse(JSON.stringify(rows));
      for (const row of newRows) {
        // Skip gateways
        if (devices[row.id].basicInfo.deviceType != 18) {
          const response = await createConfigFile({
            sitePublicId: site.siteInfo.publicId,
            devicePublicId: row.id
          });

          if (response?.data) {
            row.ConfigFileUrl = response.data;
            row.Status = Status.Syncing;
            setRows(newRows);
            const gatewaySyncResponse = await handleConfigFileUpload(row);
            if (gatewaySyncResponse !== void 0) {
              row.Status = gatewaySyncResponse;
              setRows(newRows);
            }
          } else {
            // TODO: Parameterize this string and localize it.
            throw new Error('Failed to create config files');
          }
        }
      }
      setSyncStatus(Status.Synced);
    } catch (error) {
      setSyncStatus(Status.Error);
    }
    setIsSyncDialogOpen(false);
    setGwOnlineStatus(Status.Online);
  };

  const handleSyncOneStationButton = async (device: RowElem) => {
    // Fetch list of config files
    // set the gateway's row as busy
    const dialogTitle = t('Gateway_DeviceSync_Title');
    const dialogContent = t('Gateway_DeviceSync_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Busy);
    setSyncStatus(Status.Waiting);
    try {
      const response = await createConfigFile({
        sitePublicId: site.siteInfo.publicId,
        devicePublicId: device.id
      });
      if ('data' in response) {
        const newRows = JSON.parse(JSON.stringify(rows));
        for (const row of newRows) {
          if (row.id === device.id) {
            row.ConfigFileUrl = response.data;
            row.Status = Status.Syncing;
            setRows(newRows);
            const gatewaySyncResponse = await handleConfigFileUpload(row);
            if (gatewaySyncResponse !== void 0) {
              row.Status = gatewaySyncResponse;
              setRows(newRows);
            }
          }
        }
        setSyncStatus(Status.Synced);
      } else {
        throw new Error('Failed to create config files');
      }
    } catch (error) {
      setSyncStatus(Status.Error);
    }
    setIsSyncDialogOpen(false);
    setGwOnlineStatus(Status.Online);
  };

  const handleSyncGatewayButton = async () => {
    // Fetch list of config files, but filter out non-gateway devices
    const createConfigFilesData = {
      sitePublicId: site.siteInfo.publicId,
      devicePublicIds: Object.entries(devices)
        .filter(([, device]: [string, IDevice]) => device.basicInfo.deviceType === 18)
        .map(([, device]: [string, IDevice]) => device.publicId)
    };
    // Verify we have at least one gateway
    if (createConfigFilesData.devicePublicIds.length === 0) {
      throw new Error('No devices found');
    }
    // set the gateway's row as busy
    const dialogTitle = t('Gateway_GatewaySync_Title');
    const dialogContent = t('Gateway_GatewaySync_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Busy);
    setSyncStatus(Status.Waiting);

    // Generate config files and upload
    try {
      const newRows = JSON.parse(JSON.stringify(rows));
      for (const row of newRows) {
        if (devices[row.id].basicInfo.deviceType == 18) {
          const response = await createConfigFile({
            sitePublicId: site.siteInfo.publicId,
            devicePublicId: row.id
          });

          if ('data' in response) {
            row.ConfigFileUrl = response.data;
            row.Status = Status.Syncing;
            setRows(newRows);
            const gatewaySyncResponse = await handleConfigFileUpload(row);
            if (gatewaySyncResponse !== void 0) {
              row.Status = gatewaySyncResponse;
              setRows(newRows);
            }
          } else {
            throw new Error('Failed to create config files');
          }
        }
      }
    } catch (error) {
      setSyncStatus(Status.Error);
    }
    setIsSyncDialogOpen(false);
    setGwOnlineStatus(Status.Online);
  };

  // ************************************ GATEWAY FIRMWARE UPDATE ************************************
  const handleUpdateGatewayFirmwareButton = async () => {
    setGWFirmwareDialogOpen(false);
    // set the gateway's row as busy
    const dialogTitle = t('Gateway_GatewayFirmwareUpdate_Title');
    const dialogContent = t('Gateway_GatewayFirmwareUpdate_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Busy);
    setSyncStatus(Status.Waiting);

    const deviceModel = 'IXGW-GW';
    const selectedDevice = gateway;

    const latestFirmwareName = latestFirmwareList[deviceModel].enhanced.name;
    const deviceFirmwareUrl = firmwareUrl + latestFirmwareList[deviceModel].enhanced.name;
    const downloadResponse = await fetch(deviceFirmwareUrl);
    const isDeviceFirstSync = selectedDevice.lastSyncedOn === null;
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;

    const url = await downloadResponse.text();
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass
    };
    const deviceInfo = {
      deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
      deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
      deviceType: selectedDevice?.basicInfo.deviceType,
      deviceId: systemId ? systemId : isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
      devicePassword: systemPassword
        ? systemPassword
        : isDeviceFirstSync
        ? 'admin'
        : selectedDevice?.basicInfo.adminPass,
      deviceFirmwareFileName: latestFirmwareName,
      deviceFirmwareLink: url
    };

    try {
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.FIRMWARE_UPDATE, gatewayInfo, deviceInfo, null);
      // set status to Updating
      setGwOnlineStatus(Status.Updating);
      await handleGatewayCommand(ioTPayload).unwrap();

      // wait 5 minutes
      // TODO: improve this to check the status of the firmware update, especially if there is an early failure response
      await new Promise((resolve) => setTimeout(resolve, 300000));

      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.FIRMWARE_UPDATE, gatewayInfo, deviceInfo, null);
      let fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      if (fetchResponse?.statusCode.includes('206')) {
        fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      }
      // Check the payload for the ip address to see if firmware update was successful
      const statusCode = fetchResponse?.payload[0].statusCode.slice(0, 3);
      if (statusCode === '200') {
        setShowAlert(true);
        // update the device firmware version in redux and the database
        const fwVersion = Number(latestFirmwareList[deviceModel].enhanced.version / 100).toFixed(2);
        const updateDevicePayload = {
          ...selectedDevice,
          basicInfo: {
            ...selectedDevice.basicInfo,
            firmwareVersion: fwVersion
          }
        };
        await updateDevice(updateDevicePayload);
        setRows(rows);
      } else {
        setRows(rows);
        setErrorMessage(t('GatewayError_FirmwareUpdateError'));
      }
    } catch (error) {
      setErrorMessage(t('GatewayError_FirmwareUpdateError'));
    }
    setIsSyncDialogOpen(false);
    setGwOnlineStatus(Status.Online);
  };

  // *************************************** Sync data to ACL ***************************************
  const syncAcl = async () => {
    try {
      const propertyId = site.siteInfo.awsPropertyId;
      const siteId = site?.siteInfo?.publicId;
      const callingUserPublicId = localStorage.getItem('userId') ?? '';

      // find any devices with device type 18
      const adaptor = Object.values(devices).find((device) => device.basicInfo.deviceType === 18);
      if (adaptor && !isGWRegistered) {
        if (adaptor.gatewaySettings?.gatewaySerialNumber !== null) {
          const serial = getSerialNumber(adaptor.basicInfo.macAddress);
          // update device with serial number
          const updatedDeviceParams = {
            device: {
              publicId: adaptor.publicId,
              basicInfo: {
                serialNumber: serial
              }
            }
          };
          updateDevice(updatedDeviceParams);
        }
      }

      // check if there are any units
      const areThereAnyUnits = Object.values(unitList).length > 0;
      if (!areThereAnyUnits) {
        throw new Error(t('Error_No_Units_Found'));
      }

      const dialogTitle = t('Sync_IXGCloudTitle');
      const dialogContent = t('Sync_IXGCloudContent');
      setSyncDialogTitle(dialogTitle);
      setSyncDialogContent(dialogContent);
      setIsSyncDialogOpen(true);

      if (isFirstSyncToIXGCloud) {
        const sync_result = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'upload',
            propertyId: propertyId,
            token: acltoken,
            siteId,
            userId: callingUserPublicId
          })
        });
        if (sync_result.status !== 200) {
          throw new Error('Failed to sync with IXG Cloud');
        }
        setIsFirstSyncToIXGCloud(false);

        // wait 1 minute
        await new Promise((resolve) => setTimeout(resolve, 60000));
      }
      const qrResult = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'authcode',
          propertyId: propertyId,
          token: acltoken,
          siteId,
          userId: callingUserPublicId,
          unitIds: unitIds,
          roomIds: roomIds
        })
      });

      const message = await qrResult.body
        ?.getReader()
        .read()
        .then(({ value }) => new TextDecoder().decode(value));

      const appUnitIds = [];
      const appIds = [];
      for (const app in appList) {
        appUnitIds.push(appList[app].unitPublicId);
        appIds.push(app);
      }

      const sync_result = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'setqrcodes',
          userId: callingUserPublicId,
          unitIds: appUnitIds,
          appIds
        })
      });

      if (message === 'Success' && sync_result.status === 200) {
        // show success message
        setShowIXGSync(true);
      }
      setIsSyncDialogOpen(false);
    } catch (error) {
      setErrorMessage(t('Failed_to_sync_with_IXG_Cloud') + (error as Error).message);
      setIsSyncDialogOpen(false);
    }
  };

  // check each device for the last device.networkSettings.lastSyncedOn and if it is null, set to 'Standby' otherwise, set to 'Synced'
  const buildDeviceRow = (devices: IDevice[]): RowElem[] => {
    return Object.entries(devices).map(([, device]: [string, IDevice]) => ({
      id: device.basicInfo.devicePublicId,
      MacAddress: device.basicInfo.macAddress,
      StationNumber: device.basicInfo.stationNumber,
      StationName: device.basicInfo.stationName,
      ModelNumber: getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType),
      FirmwareSeries: checkFirmwareSeries(device?.basicInfo?.firmwareVersion) || 'Unknown',
      FirmwareStatus: checkCurrentFirmwareStatus(device, latestFirmwareList) || 'Unknown',
      Status: getDeviceStatus(device),
      ConfigFileUrl: '-'
    }));
  };

  // Hide the TestConfigUrl for production accounts
  const columnVisibilityModel = React.useMemo(() => {
    if (isProductionAccount) {
      return {
        ConfigFileUrl: false
      };
    }
    return {
      ConfigFileUrl: true
    };
  }, [isProductionAccount]);

  // ************************************ Grid Columns ************************************
  const columns: GridColDef[] = [
    {
      field: 'MacAddress',
      headerName: t('MAC_Address'),
      width: 150,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      cellClassName: 'custom-column'
    },
    {
      field: 'ModelNumber',
      headerName: t('Model_Number'),
      width: 120,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      cellClassName: 'custom-column'
    },
    {
      field: 'StationName',
      headerName: t('Station_Name'),
      width: 130,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      )
    },
    {
      field: 'StationNumber',
      headerName: t('Station_Number'),
      width: 120,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      )
    },
    {
      field: 'Status',
      headerName: t('Station_Status'),
      width: 220,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params) => (
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Chip
            sx={{
              ...styles.tableButtons,
              borderColor: !hasEditPermission ? 'grey' : 'default',
              pointerEvents: !hasEditPermission ? 'none' : 'auto'
            }}
            label={
              <Typography
                variant="body2"
                sx={{
                  color: !hasEditPermission ? 'grey' : 'default'
                }}
              >
                <div
                  style={{ cursor: params.value !== Status.StandBy ? 'pointer' : 'default' }}
                  onClick={() => {
                    if (hasEditPermission) {
                      if (params.value === Status.NeedsStationNumber) {
                        const rowData = params.row;
                        handleNavigateToStationInfo(rowData);
                      }
                      if (params.value === Status.NeedsIPAddress || params.value === Status.NeedsAssociation) {
                        const rowData = params.row;
                        handleNavigateToNetworkSettings(rowData);
                      }
                      if (params.value === Status.NeedsUnit) {
                        handleNavigateToUnit();
                      }
                      if (params.value === Status.NeedsCallList) {
                        const rowData = params.row;
                        handleNavigateToCallList(rowData);
                      }
                      if (params.value === Status.NeedsAddressBook) {
                        const rowData = params.row;
                        handleNavigateToAddressBook(rowData);
                      }
                      if (params.value === Status.NeedsSync) {
                        const rowData = params.row;
                        params.row.Status = Status.Waiting;
                        handleSyncOneStationButton(rowData);
                      }
                      if (params.value === Status.Error) {
                        const rowData = params.row;
                        params.row.Status = getDeviceStatus(devices[rowData.id]);
                      }
                    }
                  }}
                >
                  {params.value}
                </div>
              </Typography>
            }
            color={getColorFromStatus(params.value)}
            size="medium"
            variant="outlined"
          />
        </Box>
      )
    },
    {
      field: 'FirmwareStatus',
      headerName: t('Firmware_Status'),
      width: 160,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Chip
              sx={{
                ...styles.tableButtons,
                borderColor: !hasEditPermission ? 'grey' : 'default',
                pointerEvents: !hasEditPermission ? 'none' : 'auto'
              }}
              label={
                <Typography
                  variant="body2"
                  sx={{
                    color: !hasEditPermission ? 'grey' : 'default'
                  }}
                >
                  <div
                    style={{ cursor: params.value === Status.NeedsUpdate ? 'pointer' : 'default' }}
                    onClick={() => {
                      if (hasEditPermission) {
                        const rowData = params.row as RowElem;
                        handleNavigateToFirmwareUpdate(rowData);
                      }
                    }}
                  >
                    {params.value}
                  </div>
                </Typography>
              }
              color={getColorFromStatus(params.value)}
              size="medium"
              variant="outlined"
            />
          </Box>
        );
      }
    },
    {
      field: 'soundFile',
      headerName: t('Sync_Sound_File'),
      width: 120,
      renderHeader: (params) => (
        <Typography variant="body2" sx={{ fontWeight: 'bold', whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: (params) => {
        const excludedModelNumbers = [
          'IXGW-TGW',
          'IXGW-GW',
          'IXW-MA',
          'IXW-MAA',
          'IXGW-LC',
          'IXGW-LC-RY20',
          'IXG-2C7',
          'IXG-2C7-L'
        ];
        const rowData = params.row as RowElem;
        if (rowData.ModelNumber && excludedModelNumbers.includes(rowData.ModelNumber)) {
          return null;
        }
        return (
          <Button
            variant="outlined"
            size="small"
            disabled={!hasEditPermission || loadingRows[rowData.id]}
            onClick={() => {
              handleUploadSoundFiles(rowData);
            }}
          >
            {loadingRows[rowData.id] ? <CircularProgress size={24} /> : 'Sync Sound'}
          </Button>
        );
      }
    },
    {
      field: 'ConfigFileUrl',
      headerName: 'Test Config Url',
      width: 0,
      hide: true,
      renderHeader: (params) => (
        <Typography variant="body2" sx={styles.tableHeaders}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell(params) {
        if (params.row.ConfigFileUrl === '-') {
          return params.row.ConfigFileUrl;
        } else {
          return (
            <a href={params.row.ConfigFileUrl} target="_blank">
              {t('Config_File_Link')}
            </a>
          );
        }
      }
    }
  ];

  const CardComponent = ({
    icon,
    title,
    descriptions,
    buttonText,
    isButtonEnabled,
    onClick,
    buttonIcon,
    hasError = false
  }: {
    icon: ReactNode;
    title: string;
    descriptions: string | string[];
    buttonText: string;
    isButtonEnabled: boolean;
    onClick: () => void;
    buttonIcon: ReactNode;
    hasError?: boolean;
  }) => {
    const iconColor = hasError ? ORANGE_COLOR : BLUE_COLOR;
    const borderColor = hasError ? ORANGE_COLOR : GRAY_COLOR;
    const buttonColor = hasError ? ORANGE_COLOR : BLACK_COLOR;

    const descriptionArray = Array.isArray(descriptions) ? descriptions : [descriptions];

    return (
      <Card sx={styles.stationManagerCards(borderColor)}>
        <Box sx={styles.iconColumn}>{React.cloneElement(icon as React.ReactElement, { sx: { color: iconColor } })}</Box>
        <Box sx={styles.textColumn}>
          <Typography variant="h6">{title}</Typography>
          {descriptionArray.map((desc, index) => (
            <Typography key={index} variant="caption" sx={styles.description}>
              {desc}
            </Typography>
          ))}
          <CardActions sx={styles.cardActions}>
            <Button
              variant="outlined"
              onClick={onClick}
              disabled={!isButtonEnabled || !hasEditPermission}
              startIcon={buttonIcon}
              sx={{ color: buttonColor, borderColor: buttonColor }}
            >
              {buttonText}
            </Button>
          </CardActions>
        </Box>
      </Card>
    );
  };

  return (
    <>
      {isSyncDialogOpen && (
        <Dialog open={isSyncDialogOpen} onClose={handleClose} style={styles.syncModal} fullWidth maxWidth="md">
          <DialogTitle style={{ display: 'flex', justifyContent: 'center' }}>{syncDialogTitle}</DialogTitle>
          <Box sx={styles.syncModal}>
            <CircularProgress sx={styles.syncModal} size="40px" color="info" />
          </Box>
          <Box sx={{ padding: '20px' }}>
            <Typography variant="caption" textAlign="center">
              {syncDialogContent}
            </Typography>
          </Box>
        </Dialog>
      )}
      <Paper elevation={3} sx={styles.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Box sx={styles.title}>{t('Gateway_Action_Center')}</Box>
              {!hasEditPermission && <Box sx={styles.viewPermission}>{t('View_only_Permission')}</Box>}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <CardComponent
                    icon={<CloudFilledIcon />}
                    title={t('Gateway_Status')}
                    descriptions={
                      gwOnlineStatus === Status.Online
                        ? (t('Gateway_Online_Descriptions', { returnObjects: true }) as string[])
                        : gwOnlineStatus === Status.Unknown ||
                          gwOnlineStatus === Status.Offline ||
                          gwOnlineStatus === Status.Error
                        ? (t('Check_Gateway_Status', { returnObjects: true }) as string[])
                        : gwOnlineStatus === Status.Checking
                        ? (t('Gateway_Checking_Descriptions', { returnObjects: true }) as string[])
                        : (t('Gateway_Offline_Descriptions', { returnObjects: true }) as string[])
                    }
                    buttonText={t('Check_Gateway_Status')}
                    isButtonEnabled={
                      gwOnlineStatus === Status.Online ||
                      gwOnlineStatus === Status.Unknown ||
                      gwOnlineStatus === Status.Offline ||
                      gwOnlineStatus === Status.Error
                    }
                    onClick={getGatewayOnlineStatus}
                    buttonIcon={<Cached />}
                    hasError={
                      !(
                        gwOnlineStatus === Status.Online ||
                        gwOnlineStatus === Status.Unknown ||
                        gwOnlineStatus === Status.Offline ||
                        gwOnlineStatus === Status.Error
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CardComponent
                    icon={<Memory />}
                    title={t('Gateway_Firmware')}
                    descriptions={
                      isGWRegistered && gwOnlineStatus === Status.Online
                        ? (t('Gateway_Update_Firmware_Descriptions', { returnObjects: true }) as string[])
                        : gwOnlineStatus === Status.Updating
                        ? (t('Gateway_Updating_Firmware_Descriptions', { returnObjects: true }) as string[])
                        : (t('Gateway_Update_Firmware_Required', { returnObjects: true }) as string[])
                    }
                    buttonText={t('Update_Firmware_Button')}
                    onClick={() => {
                      setGWFirmwareDialogOpen(true);
                    }}
                    buttonIcon={<Cached />}
                    isButtonEnabled={isGWRegistered && gwOnlineStatus === Status.Online}
                    hasError={!(isGWRegistered && gwOnlineStatus === Status.Online)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CardComponent
                    icon={<DeviceHub />}
                    title={t('Gateway_Configuration')}
                    descriptions={
                      !gateway?.basicInfo?.firmwareVersion || gateway?.basicInfo?.firmwareVersion < 3.81
                        ? (t('Gateway_Firmware_Update_Required', { returnObjects: true }) as string[])
                        : gateway?.lastSyncedOn
                        ? (t('Gateway_Configuration_File_Synced', { returnObjects: true }) as string[])
                        : (t('Gateway_Sync_Configuration', { returnObjects: true }) as string[])
                    }
                    buttonText={t('Sync_Gateway_Button')}
                    onClick={handleSyncGatewayButton}
                    buttonIcon={<Cached />}
                    isButtonEnabled={
                      isGWRegistered && gwOnlineStatus === Status.Online && gateway?.basicInfo?.firmwareVersion >= 3.81
                    }
                    hasError={
                      !(
                        isGWRegistered &&
                        gwOnlineStatus === Status.Online &&
                        gateway?.basicInfo?.firmwareVersion >= 3.81
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <CardComponent
                    icon={<PhoneIphone />}
                    title={t('IXG_App_Configuration')}
                    descriptions={
                      !acltoken
                        ? (t('IXG_Cloud_Login_Descriptions', { returnObjects: true }) as string[])
                        : (t('IXG_Cloud_Sync_Descriptions', { returnObjects: true }) as string[])
                    }
                    buttonText={acltoken ? t('Sync_IXG_Cloud_Button') : t('Login_IXG_Cloud_Button')}
                    onClick={acltoken ? syncAcl : handleOpenLoginDialog}
                    buttonIcon={<Cached />}
                    isButtonEnabled={isGatewayUnregistered || isGWRegistered}
                    hasError={!acltoken}
                  />
                </Grid>
              </Grid>
              {isLoginDialogOpen && (
                <Dialog open={isLoginDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
                  <LoginPanel
                    handleClose={handleClose}
                    setUsernameAndToken={setUsernameAndToken}
                    setIsLoginDialogOpen={setIsLoginDialogOpen}
                  />
                </Dialog>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box sx={styles.title}>{t('Station_Manager')}</Box>
                  {!isGWRegistered && (
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    <Alert severity="warning" onClose={() => {}}>
                      {t('Gateway_not_registered_warning')}
                    </Alert>
                  )}
                  {hasEditPermission && (
                    <Box sx={styles.buttonContainer}>
                      <Tooltip title={t('Check_the_status_of_the_devices')}>
                        <Button
                          sx={{ mr: 2, color: NERO_COLOR, borderColor: NERO_COLOR }}
                          variant="outlined"
                          startIcon={<SyncIcon />}
                          disabled={
                            !hasEditPermission ||
                            !isGWRegistered ||
                            gwOnlineStatus !== Status.Online ||
                            gateway?.basicInfo?.firmwareVersion < 3.81
                          }
                          onClick={getGatewayOnlineStatus}
                        >
                          {syncStatus === Status.Syncing ? t('Checking') : t('Device_Check')}
                        </Button>
                      </Tooltip>
                      <Tooltip title={t('Upload_configuration_files_tooltip')}>
                        <Button
                          sx={{ mr: 2, color: NERO_COLOR, borderColor: NERO_COLOR }}
                          variant="outlined"
                          startIcon={<SyncIcon />}
                          disabled={
                            !hasEditPermission ||
                            !isGWRegistered ||
                            gwOnlineStatus !== Status.Online ||
                            gateway?.basicInfo?.firmwareVersion < 3.81
                          }
                          onClick={handleSyncStationsButton}
                        >
                          {syncStatus === Status.Syncing ? t('Syncing') : t('Sync_Station_Configuration')}
                        </Button>
                      </Tooltip>
                      <Tooltip title={t('Update_all_devices_tooltip')}>
                        <Button
                          sx={{ mr: 2, color: NERO_COLOR, borderColor: NERO_COLOR }}
                          variant="outlined"
                          startIcon={<UpdateIcon />}
                          onClick={handleSyncFirmware}
                          disabled={!hasEditPermission || !isGWRegistered || gwOnlineStatus !== Status.Online}
                        >
                          Update Station Firmware
                        </Button>
                      </Tooltip>
                      <Tooltip title={t('Device_Check_Tooltip')}>
                        <Button
                          sx={{ mr: 2, color: NERO_COLOR, borderColor: NERO_COLOR }}
                          variant="outlined"
                          startIcon={<DeviceUnknownIcon />}
                          disabled={
                            !hasEditPermission ||
                            !isGWRegistered ||
                            gwOnlineStatus !== Status.Online ||
                            gateway?.basicInfo?.firmwareVersion < 3.81
                          }
                          onClick={handleDeviceCheckButton}
                        >
                          {gwOnlineStatus === Status.Checking ? t('Checking') : t('Device_Check')}{' '}
                        </Button>
                      </Tooltip>
                    </Box>
                  )}
                  <Box sx={{ height: 500, width: '100%' }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      columnVisibilityModel={columnVisibilityModel}
                      loading={isLoading}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {isGWFirmwareDialogOpen && (
            <Dialog
              open={isGWFirmwareDialogOpen}
              onClose={() => setGWFirmwareDialogOpen(false)}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>
                Gateway Firmware Update
                <IconButton
                  aria-label="close"
                  onClick={() => setGWFirmwareDialogOpen(false)}
                  sx={styles.buttonContainer2}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContent>
                  {t('Latest_version_of_firmware')}
                  <Typography component="span" style={{ whiteSpace: 'pre' }}>
                    {' '}
                  </Typography>
                  <b>{latestFirmwareList['IXGW-GW'].enhanced.name.replace('.bin', '')}</b> (version{' '}
                  {latestFirmwareList['IXGW-GW'].enhanced.version / 100})
                  <Typography component="span" style={{ whiteSpace: 'pre' }}>
                    {' '}
                  </Typography>
                  {t('Is_available_for_Gateway')}
                  <Typography>{t('Would_you_like_to_proceed_with_updating')}</Typography>
                </DialogContent>
              </DialogContent>
              <Box sx={styles.dialogBox1}>
                <Button onClick={handleUpdateGatewayFirmwareButton} color="primary" variant="outlined">
                  {t('Confirm_Update')}
                </Button>
              </Box>
            </Dialog>
          )}
        </Grid>
      </Paper>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={StringUtils.format(getString('Update_Success'), getString('Site'))}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={t('Success_Synced_Device')}
        isOpen={showIXGSync}
        onClose={() => setShowIXGSync(false)}
      />
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  paper: {
    padding: '5px',
    height: '100%'
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  dataGrid: {
    '& .custom-column': {
      backgroundColor: '#f5f5f5',
      border: '1px solid #ccc'
    }
  },
  stationManagerCards: (color: string) => ({
    width: 290,
    height: 160,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    border: `1px solid ${color}`,
    backgroundColor: '#fff',
    boxShadow: `0px 0px 0px 1px #E0E0E0`,
    padding: '10px'
  }),
  iconColumn: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginRight: '10px'
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%'
  },
  cardActions: {
    justifyContent: 'flex-start',
    marginTop: 'auto',
    paddingLeft: '0'
  },
  icon: {
    fontSize: 24
  },
  description: {
    marginBottom: '4px',
    lineHeight: '1.3'
  },
  avatars: {
    width: 40,
    height: 30,
    marginRight: '5px',
    backgroundColor: 'transparent'
  },
  gwContainer: {
    display: 'left',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1rem'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '5px',
    backgroundColor: '#f5f5f5'
  },
  toolboxContainer: {
    display: 'left',
    justifyContent: 'end'
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  syncModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    AlignHorizontalCenter: 'center',
    margin: '10px'
  },
  tableHeaders: {
    fontWeight: 'bold',
    whiteSpace: 'normal',
    wordWrap: 'break-word'
  },
  tableButtons: {
    height: 'auto',
    borderRadius: '100px',
    padding: '4px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    marginTop: '10px'
  },
  buttonContainer2: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme: Theme) => theme.palette.grey[500]
  },
  dialogBox1: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'right'
  },
  viewPermission: {
    display: 'flex',
    fontWeight: 'bold',
    padding: '5px',
    margin: '10px',
    backgroundColor: '#f5f5f5'
  }
};

export default Site;
