import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DeviceUpdateHelper } from '../../utils/deviceUpdateHelpers';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  useLazyGetDeviceListWithSitePublicIdQuery,
  useBatchUpdateDevicesMutation,
  useGetDeviceListWithSitePublicIdQuery,
  useGetUnitListWithSitePublicIdQuery,
  useLazyGetAppWithSiteIdQuery,
  useUpdateDeviceMutation
} from 'services/aiphoneCloud';
import { useParams } from 'react-router-dom';
import { ISound } from 'features/RemoteManagement/DeviceDashboard/addressBook/types';
import { getAllSoundsBySiteId } from 'shared/api/Aws/RemoteManagementApi';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useTranslation } from 'react-i18next';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import CONFIG from 'config';
import { getSerialNumber } from 'shared/utils/helperFunctions';

// Fixed some promise-based issues,
//   missing dependencies issues,
//   unused prop definition issues,
//   and nullish coalescing operator issues
//   that were found by Webstorm

interface ISiteConfirmationProps {
  handleNextStep: () => void;
}

const SiteConfirmation = ({ handleNextStep }: ISiteConfirmationProps) => {
  const [isConfiguring, setIsConfiguring] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [soundsList, setSoundsList] = useState<ISound[]>([]);
  const [siteHasSynced, setSiteHasSynced] = useState<boolean>(false);
  const [syncMessage, setSyncMessage] = useState<string>('');
  const { id: siteId = '' } = useParams();
  const { t } = useTranslation();
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const appList = useSelector((state: RootState) => state.apps.AppList);
  const site = useSelector((state: RootState) => state.site);
  const users = useSelector((state: RootState) => state.users);
  const systemType = useSelector((state: RootState) => state.site.siteInfo.typeId);
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();

  const system = new DeviceUpdateHelper(deviceList, unitList, systemType, siteId);
  const { data: deviceData } = useGetDeviceListWithSitePublicIdQuery({
    sitePublicId: siteId,
    qty: -1,
    page: 0
  });
  const { data: unitData } = useGetUnitListWithSitePublicIdQuery({ sitePublicId: siteId, qty: -1, page: 0 });
  const [fetchApps] = useLazyGetAppWithSiteIdQuery();
  const [updateDevices] = useBatchUpdateDevicesMutation();
  const [updateDevice] = useUpdateDeviceMutation();

  const getAllSoundsPayload = {
    siteId: site.siteInfo.publicId,
    userId: users.currentUser?.publicId ?? ''
  };

  const setupSounds = async () => {
    const response = await getAllSoundsBySiteId(getAllSoundsPayload);
    const allSounds: ISound[] = response || [];
    setSoundsList(allSounds);
  };

  const syncWithACL = async () => {
    const propertyId = site.siteInfo.awsPropertyId;
    const callingUserPublicId = users.currentUser?.publicId;
    const aclToken = localStorage.getItem('acltoken');

    if (Object.keys(unitList).length === 0) {
      setSiteHasSynced(true);
      return;
    }

    if (!site.siteInfo.registeredGatewayPublicId) {
      setSiteHasSynced(true);
      return;
    }

    try {
      if (site.siteInfo.registeredGatewayPublicId) {
        const params = {
          device: {
            publicId: site.siteInfo.registeredGatewayPublicId,
            basicInfo: {
              serialNumber: getSerialNumber(deviceList[site.siteInfo.registeredGatewayPublicId].basicInfo.macAddress)
            }
          }
        };

        setSyncMessage('Updating Gateway...');

        await updateDevice(params);
      } else {
        throw new Error('No registered gateway found');
      }
    } catch (error) {
      console.error('Error updating gateway', error);
    }

    try {
      setSyncMessage('Syncing gateway with cloud...');
      await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'upload',
          propertyId: propertyId,
          token: aclToken,
          siteId,
          userId: callingUserPublicId
        })
      });
    } catch (error) {
      console.error('Error syncing with ACL', error);
    }

    try {
      const unitIds: string[] = [];
      const roomIds: number[] = [];
      const unitsList = Object.values(unitList);

      if (Array.isArray(unitsList)) {
        for (let i = 0, len = unitsList.length; i < len; i++) {
          if (unitsList[i].appPublicIds !== null) {
            unitIds.push(unitsList[i].publicId);
            roomIds.push(unitsList[i].unitSequentialNumber);
          }
        }
      }

      if (unitIds.length === 0) {
        setSiteHasSynced(true);
        setSyncMessage('No units with apps to sync');
        return;
      }

      //await 60 seconds before continuing due to ACL servers
      setSyncMessage(`Configuring Site...please wait as this may take up to a minute`);
      await new Promise((resolve) => setTimeout(resolve, 60000));

      setSyncMessage('Configuring Mobile Apps...');
      const qrResult = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'authcode',
          propertyId: propertyId,
          token: aclToken,
          siteId,
          userId: callingUserPublicId,
          unitIds: unitIds,
          roomIds: roomIds
        })
      });

      await qrResult.body
        ?.getReader()
        .read()
        .then(({ value }) => new TextDecoder().decode(value));

      const appUnitIds = [];
      const appIds = [];
      for (const app in appList) {
        appUnitIds.push(appList[app].unitPublicId);
        appIds.push(app);
      }

      setSyncMessage('Setting QR Codes...');
      if (appUnitIds.length !== 0) {
        const sync_result = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'setqrcodes',
            userId: callingUserPublicId,
            unitIds: appUnitIds,
            appIds
          })
        });
      }

      await fetchApps({
        sitePublicId: siteId,
        qty: 500,
        page: 0
      });

      setSyncMessage('Finishing up...');
      setSiteHasSynced(true);
    } catch (error) {
      console.error('Error syncing with ACL', error);
    }
  };

  const handleConfigureSettings = async () => {
    try {
      await system.configureAddressBook(soundsList);
      await system.configureCallSettings();
      await system.configureDoorRelease();
      await updateDevices(system.getPayload())
        .unwrap()
        .then(() => {
          setIsConfiguring(false);
          handleNextStep();
        })
        .catch((error: any) => {
          setIsError(true);
          setErrorMessage(error?.data?.message);
          setIsConfiguring(false);
        })
        .finally(() => {
          setIsConfiguring(false);
        });
    } catch (error: any) {
      // Need to catch the spinning state
      setIsError(true);
      setErrorMessage(error?.data?.message);
      setIsConfiguring(false);
    }
  };

  useEffect(() => {
    if (!siteId) return;
    fetchDevices({
      sitePublicId: siteId,
      qty: -1,
      page: 0
    });

    if (soundsList.length === 0) {
      (async () => {
        await setupSounds();
      })();
    }
  }, []);

  useEffect(() => {
    syncWithACL();
  }, [unitData]);

  useEffect(() => {
    const configureSettings = async () => {
      if (deviceData && unitData && soundsList.length > 0) {
        await handleConfigureSettings();
      }
    };

    if (siteHasSynced) {
      configureSettings();
    }
  }, [deviceData, unitData, soundsList, siteHasSynced]);

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={styles.centerContent}>
          <Box>
            <Typography variant="h4" sx={styles.title}>
              {t('Configuring_Site_Title')}
            </Typography>
            <Typography variant="body1" sx={styles.description}>
              {t('Configuring_Site_Description')}
            </Typography>
          </Box>
          <Box>{isConfiguring && <Spinner />}</Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: '140px',
              left: 0,
              width: '100vw',
              height: `calc(100vh - 64px)`,
              zIndex: 1500
            }}
          >
            {syncMessage}
          </Box>
        </Box>
      </Container>
      <SnackbarAlert type="error" time={5000} isOpen={isError} onClose={() => setIsError(false)} text={errorMessage} />
    </>
  );
};

const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2rem'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {}
};

export default SiteConfirmation;
