import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridToolbarContainer
} from '@mui/x-data-grid';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AnsweringStationIcon, DoorStationIcon, EntranceStationIcon } from 'shared/icons/icons';
import { RootState } from 'store';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import AddDeviceToUnitDialog from '../../../components/AddDeviceToUnit/AddDeviceToUnitDialog';

const DevicesDatagrid = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const unitPublicId = useParams().unitid || '';
  const navigate = useNavigate();
  const location = useLocation();
  const [updateDevice] = useUpdateDeviceMutation();

  const handleAddDevice = () => {
    setIsOpen(true);
  };

  const handleViewDevice = useCallback(
    (devicePublicId: string) => () => {
      const basePath = location.pathname.split('/units')[0];
      navigate(`${basePath}/devices/${devicePublicId}/stationinfo`);
    },
    [location.pathname, navigate]
  );

  const handleRemoveFromUnit = useCallback(
    (id: GridRowId) => () => {
      const params = {
        device: {
          unitPublicId: null,
          publicId: id
        }
      };

      updateDevice(params);
    },
    []
  );

  const generateRows = () => {
    return Object.entries(deviceList)
      .map(([key, device]) => {
        // Check if device is null or device.unitPublicId is not equal to unitPublicId
        if (!device || device.unitPublicId !== unitPublicId) return null;
        let deviceIcon;
        if (
          deviceListByType['MasterStation'].includes(device.publicId) ||
          deviceListByType['GuardStation'].includes(device.publicId) ||
          deviceListByType['SubMasterStation'].includes(device.publicId) ||
          deviceListByType['TenantStation'].includes(device.publicId)
        ) {
          deviceIcon = <AnsweringStationIcon viewBox="0 0 25 25" />;
        } else if (
          deviceListByType['VideoDoorStation'].includes(device.publicId) ||
          deviceListByType['AudioDoorStation'].includes(device.publicId) ||
          deviceListByType['EmergencyStation'].includes(device.publicId)
        ) {
          deviceIcon = <DoorStationIcon viewBox="0 0 25 25" />;
        } else if (deviceListByType['EntranceStation'].includes(device.publicId)) {
          deviceIcon = <EntranceStationIcon viewBox="0 0 25 25" />;
        } else {
          deviceIcon = null; // Handle the case when devicePublicId doesn't match any device type
        }

        return {
          id: key,
          deviceType: deviceIcon,
          stationNumber: device.basicInfo.stationNumber,
          stationName: device.basicInfo.stationName
        };
      })
      .filter((row) => row !== null);
  };

  const columns = [
    {
      field: 'deviceType',
      headerName: 'Type',
      width: 50,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <Box sx={styles.deviceIcon} key={params.value}>
            {params.value}
          </Box>
        </>
      ),
      sortable: false
    },
    { field: 'stationNumber', headerName: 'Station #', width: 100 },
    { field: 'stationName', headerName: 'Station Name', width: 160 },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem label="View Device" onClick={handleViewDevice(params.row.id)} showInMenu={true} />
      ]
    }
  ];

  const rows = generateRows();

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddDevice}>
          Add Device to Unit
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter={true}
        slots={{
          toolbar: Toolbar,
          noRowsOverlay: () => <Box sx={styles.noRows}>No devices assigned to this unit</Box>
        }}
      />
      <AddDeviceToUnitDialog selectedUnit={unitPublicId} open={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

const styles = {
  deviceIcon: {
    display: 'inline-block',
    marginRight: '5px'
  },
  noRows: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
};

export default DevicesDatagrid;
