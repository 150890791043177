import { Container } from '@mui/material';
import Site from './Site/Site';

const SiteInfo = () => {
  return <Container maxWidth="xl">{<Site />}</Container>;
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainContainer: {
    width: '100%'
  },
  siteContainer: {
    borderBottom: 1,
    borderColor: 'divider'
  }
};

export default SiteInfo;
