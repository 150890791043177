/**
 * DataGrid cell component for text input
 *
 * Pass an error if there is one
 *
 * Note: Must set `display: 'flex'` and `align: 'center'`, in the GridRowProps to make the error message appear
 * below the input
 *
 * This data grid cell **will stay open** if the input is invalid.
 *
 * This component has an underline text input and an error message underneath it if there is an error.
 */
import React from 'react';
import { GridRenderEditCellParams, GridEditInputCell } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import 'styles/frontshine.css';

const TextInputDataGridCell = React.forwardRef<HTMLDivElement, GridRenderEditCellParams>((props, ref) => {
  const { error, ...restProps } = props;

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          borderBottom: `2px solid ${error ? 'red' : 'lightgrey'}`,
          paddingLeft: '2px',
          paddingRight: '2px'
        }}
        className={'center-text-inputs'}
      >
        <GridEditInputCell {...restProps} error={!!error} />
      </div>
      {error && <small className={'text-red text-center mx-auto'}>{error}</small>}
    </Box>
  );
});

export default TextInputDataGridCell;
