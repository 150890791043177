import {
  DialogProps,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { getLatestFirmwareDownloadUrl } from 'shared/utils/firmwareFunctions';
import React from 'react';
import { useTranslation } from 'react-i18next';

// We are making a dialog to prompt the user to download the latest firmware for IXGW-GW

export interface PromptDownloadLatestFirmwareDialogProps extends DialogProps {
  deviceType: string;
  onClose: () => void;
}

export const PromptDownloadLatestFirmwareDialog = (props: PromptDownloadLatestFirmwareDialogProps) => {
  const [downloadUrl, setDownloadUrl] = React.useState<string | null>(null);
  const [downloadVersionNumber, setDownloadVersionNumber] = React.useState<string | null>(null);
  const [versionName, setVersionName] = React.useState<string | null>(null);

  const { t } = useTranslation();

  const handleDownloadPressed = React.useCallback(() => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    }
    props.onClose();
  }, [downloadUrl, props.onClose]);

  React.useEffect(() => {
    getLatestFirmwareDownloadUrl(props.deviceType).then((data: LatestFirmwareDownload | null) => {
      if (data) {
        setDownloadUrl(data.downloadUrl);
        setDownloadVersionNumber(data.versionNumber.toString());
        setVersionName(data.name);
      }
    });
  }, [props.deviceType]);

  // If there is no download URL, we don't need to show the dialog
  if (!downloadUrl) {
    return null;
  } else {
    return (
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Download Latest Firmware</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A new firmware update
            <Typography component="span" style={{ whiteSpace: 'pre' }}>
              {' '}
            </Typography>
            <b>{versionName?.replace('.bin', '')}</b> (version {downloadVersionNumber})
            <Typography component="span" style={{ whiteSpace: 'pre' }}>
              {' '}
            </Typography>
            is available for <b>{props.deviceType}</b>. Would you like to download it now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            {t('Table_Header_Elevator_No')}
          </Button>
          <Button onClick={handleDownloadPressed} color="primary">
            {t('Table_Header_Elevator_Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};
