import React, { createContext, useContext, ReactNode } from 'react';
import { CloudUser } from '../store/slices/usersSlice';
import { PermissionsContextType, FeatureName, hasCapability, hasFeatureAccess } from '../permissions/utils';

export type PermissionContextType = {
  isAllowedTo: (
    capabilityName: string,
    contextUuid: string | null | undefined,
    contextType: PermissionsContextType
  ) => boolean;

  hasFeatureAccess: (featureName: FeatureName) => boolean;
};

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => false,
  hasFeatureAccess: () => false
};

const PermissionContext = createContext<PermissionContextType>(defaultBehaviour);

type Props = {
  user: CloudUser;
  children: ReactNode;
};

export const PermissionProvider: React.FC<Props> = ({ user, children }) => {
  const isAllowedTo = (
    capabilityName: string,
    contextUuid: string | null | undefined,
    contextType: PermissionsContextType
  ) => {
    return hasCapability(capabilityName, contextUuid, contextType, user);
  };

  const checkFeatureAccess = (featureName: FeatureName) => {
    return hasFeatureAccess(featureName, user);
  };

  return (
    <PermissionContext.Provider value={{ isAllowedTo, hasFeatureAccess: checkFeatureAccess }}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = () => {
  return useContext(PermissionContext);
};
