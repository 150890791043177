/**
 * Redux parameters:
 * - selectedDevice.callSettings.contactGroupList
 */
/**
 * There are 2 ways that user navigate to this page:
 * 1. User click on the Call Destination tab on the Call Settings page.
 * 2. User click on the Call Destination from the Input/Output Settings page.
 *  - In this case, the user will be navigated to the Call Destination page with Input Number and Call Group.
 *  - The Input Number and Call Group will be pre-filled with the existing values.
 *  - User can change the Call Group and add or remove the call destination devices.
 *  -/site/:id/devices/:deviceid/callsettings?inputNumber=1&callGroup=1
 */
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { IUnit, IUnitList } from 'store/slices/unitsSlice';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import {
  filterDeviceList,
  getDeviceModelNumberFromModelType,
  getCallDestinationConfig
} from 'shared/utils/helperFunctions';
import {
  getSelectedDevice,
  updateSelectedDevice,
  IDevice,
  IDeviceContactInput,
  IContactGroupEntry
} from 'store/slices/devicesSlice';
import AddedDevices from './addedDevices/AddedDevices';
import SelectedDevice from './addedDevices/SelectedDevice';
import FilterByAddedDevices from './FilterByAddedDevices';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import ControlPanel from './controlPanel/ControlPanel';
import AddedAppUnits from './addedAppUnits/AddedAppUnits';
import SelectedAppUnit from './addedAppUnits/SelectedAppUnit';
import { Box, Card, Typography } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
import { useTranslation } from 'react-i18next';

type TFormData = {
  contactInputValue: string;
  callGroupValue: string;
  callPriorityValue: number;
};

const selectUnits = (state: RootState) => state.units;
const selectDevices = (state: RootState) => state.devices;
const selectApps = (state: RootState) => state.apps;

const CallDestination = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const units = useSelector(selectUnits);
  const deviceList = useSelector(selectDevices).DeviceList;
  const appGroup = useSelector(selectApps).AppGroup;

  const description = t('AdvancedSettings_CallDestination_Desc');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');
  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');

  const selectedDevice = useSelector(getSelectedDevice);
  /** use helper functions to get the selected device and the answering stations */
  const answeringStationIds = selectedDevice ? filterDeviceList(selectedDevice, deviceList, units) : [];
  const answeringStationList =
    answeringStationIds?.length > 0
      ? answeringStationIds.filter((id) => id !== selectedDevice?.publicId).map((id) => deviceList[id])
      : [];

  /** get the device type (example: IX-EA), fetch the drop down list for Call Priority, Call Group, Contact Group */
  const selectedDeviceModelNumber = getDeviceModelNumberFromModelType(
    selectedDevice?.basicInfo.deviceModel,
    selectedDevice?.basicInfo.deviceType
  );
  const callDropDownList = getCallDestinationConfig(selectedDeviceModelNumber);

  /** search parameter in the route */
  const [searchParams] = useSearchParams();

  const inputNumberDefaultValue: string = searchParams.get('inputNumber') ?? callDropDownList.contactGroupList[0].value;
  const callGroupDefaultValue: string = searchParams.get('callGroup') ?? callDropDownList.callGroupList[0].value;
  const callPriorityDefaultValue: number = callDropDownList.callPriorityList[0].value;

  /** helper function to get corresponding unit that belongs in app group  */
  const fetchAppUnits = () => {
    const callGroupKeys = Object.keys(appGroup);
    const unitList = units?.UnitList;
    return callGroupKeys.map((key) => unitList[key]);
  };

  const [answeringStations, setAnsweringStations] = useState<IDevice[]>(answeringStationList);
  const appUnits = fetchAppUnits();
  /** Devices and apps will be added to the call destination. */
  const [callDestinationDevices, setCallDestinationDevices] = useState<string[]>([]);
  const [callDestinationAppUnit, setCallDestinationAppUnit] = useState<IUnit[]>([]);
  const [exceedLimit, setExceedLimit] = useState<boolean>(
    callDestinationDevices.length + callDestinationAppUnit.length > 20
  );

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [needSave, setNeedSave] = useState(true);

  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();

  const [formData, setFormData] = useState<TFormData>({
    contactInputValue: inputNumberDefaultValue,
    callGroupValue: callGroupDefaultValue,
    callPriorityValue: callPriorityDefaultValue
  });

  useEffect(() => {
    handleUpdateCallDestinationValue(formData.callGroupValue);
    checkLimit();
  }, []);

  useEffect(() => {
    checkLimit();
  }, [callDestinationDevices, callDestinationAppUnit]);

  /** Upon the user's selection of the call group, configure the call destination list. */

  const handleUpdateCallDestinationValue = (value: string) => {
    const selectedContactGroupList = selectedDevice?.callSettings?.contactGroupList[value];
    const targetList = selectedContactGroupList?.targetList || [];
    if (targetList.length > 0) {
      const targetDeviceIds = targetList
        .filter((target: { targetDevicePublicId: string }) => 'targetDevicePublicId' in target)
        .map((target: { targetDevicePublicId: string }) => target.targetDevicePublicId);
      const targetAppUnitIds = targetList
        .filter((target: IContactGroupEntry) => 'targetUnitPublicId' in target)
        .map((target: IContactGroupEntry) => target.targetUnitPublicId);

      // update App Unit only if the targetAppUnitIds is not empty
      if (targetAppUnitIds.length > 0) {
        const AppUnit = units?.UnitList[targetAppUnitIds[0]];
        setCallDestinationAppUnit([AppUnit]);
      }

      setCallDestinationDevices(targetDeviceIds);
      checkLimit();
    } else {
      setCallDestinationDevices([]);
      setCallDestinationAppUnit([]);
    }
  };

  const handleFieldChange = (fieldName: string, value: string | number) => {
    setNeedSave(true);

    switch (fieldName) {
      case 'callPriorityValue':
        setFormData((prevFormData: TFormData) => ({
          ...prevFormData,
          [fieldName]: Number(value)
        }));
        break;
      case 'contactInputValue':
        setFormData((prevFormData: TFormData) => ({
          ...prevFormData,
          [fieldName]: String(value),
          callGroupValue: value === '0' ? '1' : String(value)
        }));
        navigate(`../callsettings?inputNumber=${value}&callGroup=${formData.callGroupValue}`, { relative: 'path' });
        break;
      case 'callGroupValue':
        setFormData((prevFormData: TFormData) => ({
          ...prevFormData,
          [fieldName]: String(value)
        }));
        handleUpdateCallDestinationValue(String(value));
        navigate(`../callsettings?inputNumber=${formData.contactInputValue}&callGroup=${value}`, { relative: 'path' });
        break;
    }
  };

  /*** Update Call Destination settings to be saved to DB */
  const handleUpdateCallDestination = async () => {
    // NOTE: contactGroupList in REDUX is an object with key as the call group value in UI
    const callGroupList: { [key: string]: any } = selectedDevice?.callSettings?.contactGroupList || {};
    const callGroupObj = callGroupList[formData.callGroupValue];
    const callGroupPublicId = callGroupObj?.publicId;

    const formatTargetList = callDestinationDevices.map((deviceId) => {
      if (deviceId) {
        return {
          targetDevicePublicId: deviceId,
          protocol: 1
        };
      }
    });

    // TODO: if the 'targetUnitPublicId' is not available, add to the targetList
    // add the App Group to the call destination if selected
    if (callDestinationAppUnit.length > 0 && callDestinationAppUnit[0]?.publicId) {
      formatTargetList.push({
        targetUnitPublicId: callDestinationAppUnit[0].publicId,
        protocol: 1
      });
    }
    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    // if the 'CALL BUTTON' is selected, saved only in callSettings
    if (formData.contactInputValue === '0') {
      newDevice.callSettings = {
        ...selectedDevice.callSettings,
        deviceContactGroupPublicId: callGroupPublicId,
        deviceContactGroupPriority: formData.callPriorityValue,
        contactGroupList: {
          ...callGroupList,
          [formData.callGroupValue]: {
            ...callGroupObj,
            targetList: formatTargetList
          }
        }
      };
    } else {
      newDevice.callSettings = {
        ...selectedDevice.callSettings,
        contactGroupList: {
          ...callGroupList,
          [formData.callGroupValue]: {
            ...callGroupObj,
            targetList: formatTargetList
          }
        }
      };
    }

    const updatedContactInputList = newDevice.contactInputList.map((obj: IDeviceContactInput) =>
      obj.inputNumber === Number(formData.contactInputValue)
        ? { ...obj, callPriority: formData.callPriorityValue, deviceContactGroupPublicId: callGroupPublicId }
        : obj
    );

    newDevice.contactInputList = updatedContactInputList;

    let devicePayload;
    // 'CALL BUTTON' is selected, saved only in callSettings
    if (formData.contactInputValue === '0') {
      devicePayload = {
        publicId: selectedDevice.publicId,
        callSettings: newDevice.callSettings
      };
    } else {
      devicePayload = {
        publicId: selectedDevice.publicId,
        callSettings: newDevice.callSettings,
        contactInputList: newDevice.contactInputList
      };
    }
    const updatedCallDestination = {
      device: devicePayload
    };

    try {
      const response = await updateDevice(updatedCallDestination).unwrap();
      if ('error' in response) {
        throw response.error;
      }
      dispatch(updateSelectedDevice({ device: newDevice }));

      setNeedSave(false);
      setShowAlert(true);
    } catch (error: any) {
      const err = JSON.parse(error.data);
      if (err.errorDetails.includes('Unauthorized user Id')) {
        setErrorMessage(unauthorizedUser);
      } else {
        setErrorMessage(errorUpdateDevice);
      }
    }
  };

  // set the message and alert to the user if the limit is reached,
  const checkLimit = () => {
    setExceedLimit(callDestinationDevices.length + callDestinationAppUnit.length >= 20);
    return callDestinationDevices.length + callDestinationAppUnit.length >= 20;
  };

  const onSelectDevice = (id: string) => {
    setNeedSave(true);
    if (checkLimit()) {
      setErrorMessage('You have reached the limit of 20 call destination devices');
      return;
    }
    setCallDestinationDevices([...callDestinationDevices, id]);
  };

  /** Remove selected device from the call destination list */

  const handleRemoveCallDestinationDevice = (id: string) => {
    setNeedSave(true);
    setCallDestinationDevices(callDestinationDevices.filter((deviceId) => deviceId !== id));
    checkLimit();
  };

  /** Remove mobile apps group from the call destination list */
  const handleRemoveAppUnit = (id: string) => {
    setNeedSave(true);
    setCallDestinationAppUnit(callDestinationAppUnit.filter((unit) => unit.publicId !== id));
    checkLimit();
  };

  const onSelectAppUnit = (id: string) => {
    setNeedSave(true);
    if (callDestinationDevices.length >= 20) {
      setErrorMessage('You have reached the limit. Remove a device first before add an App group');
      return;
    }
    setNeedSave(true);
    if (callDestinationAppUnit.length >= 1) {
      setErrorMessage('You have reached the limit of 1 call destination app group');
      return;
    }

    setCallDestinationAppUnit(appUnits.filter((unit) => unit.publicId === id));
    checkLimit();
  };

  /** If the list have more than 20 device, auto select the top 19 or 20
   * toggle select all will only select the top 20 or 19 devices NOT app group
   */
  const toggleSelectAll = () => {
    if (checkLimit()) {
      setErrorMessage('You have reached the limit of maximum stations allowed');
      return;
    }

    setNeedSave(true);
    const maxSections = callDestinationAppUnit.length > 0 ? 19 : 20;
    const sliceIndex = maxSections - callDestinationDevices.length;
    const possibleSelections = answeringStationList.filter(
      (device) => !callDestinationDevices.includes(device.publicId)
    );
    const selectMaxStationsAllowed = possibleSelections.slice(0, sliceIndex).map((device) => device.publicId);

    setCallDestinationDevices([...callDestinationDevices, ...selectMaxStationsAllowed]);
    setExceedLimit(true);
    setIsSelectAll((prev) => !prev);
  };

  /***  filter devices ***/
  const handleFilterDevices = (fieldName: string, query: string | boolean) => {
    if (!query) {
      setAnsweringStations(answeringStationList);
      return;
    }

    switch (fieldName) {
      case 'stationName':
        {
          const filteredList = answeringStationList.filter((device) => {
            if (typeof query === 'string') {
              return device.basicInfo.stationName.toLowerCase().includes(query.toLowerCase());
            }
            return false;
          });
          setAnsweringStations(filteredList);
        }
        break;
      case 'unitNumber': {
        const unitList: IUnitList = units.UnitList;
        for (const id in unitList) {
          if (unitList[id].unitNumber === query) {
            const deviceList = unitList[id].devicePublicIds;
            // iterate over the device list and get the device object that matches the publicId
            const filteredList = answeringStationList.filter((device) => {
              const devicePublicId = device.publicId;
              if (typeof devicePublicId === 'string') {
                return deviceList.includes(devicePublicId);
              }
            });
            setAnsweringStations(filteredList);
          }
        }
        break;
      }
      case 'stationNumber':
        {
          const filteredList = answeringStationList.filter((device) => {
            if (typeof query === 'string') {
              return device.basicInfo.stationNumber.toLowerCase().includes(query.toLowerCase());
            }
            return false;
          });
          setAnsweringStations(filteredList);
        }
        break;
      case 'showAppGroup':
        {
          setAnsweringStations([]);
        }
        break;
      default:
        setAnsweringStations(answeringStationList);
    }
  };

  return (
    <Box sx={{ maxHeight: 'var(--window-height)', overflow: 'auto' }}>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={successUpdateDevice}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      <Typography sx={styles.descriptionWrapper}>{description}</Typography>

      <Box sx={styles.controlPanelWrapper}>
        <ControlPanel
          isUpdating={isUpdating}
          needSave={needSave}
          callDropDownList={callDropDownList}
          formData={formData}
          setNeedSave={setNeedSave}
          onChange={handleFieldChange}
          onSave={handleUpdateCallDestination}
        />
      </Box>
      <Card sx={styles.devicesAndSettingsWrapper}>
        <Card sx={styles.filterDevicesWrapper}>
          <FilterByAddedDevices handleFilterDevices={handleFilterDevices} />
        </Card>
        <Box sx={styles.answeringStationsWrapper}>
          {appUnits.length > 0 && (
            <AddedAppUnits
              appUnits={appUnits}
              callDestinationAppUnit={callDestinationAppUnit}
              onSelectAppUnit={onSelectAppUnit}
              handleRemoveAppUnit={handleRemoveAppUnit}
            />
          )}
          <AddedDevices
            answeringStations={answeringStations}
            callDestinationDevices={callDestinationDevices}
            callDestinationAppUnit={callDestinationAppUnit}
            isSelectAll={isSelectAll}
            onSelectDevice={onSelectDevice}
            toggleSelectAll={toggleSelectAll}
            handleRemoveCallDestinationDevice={handleRemoveCallDestinationDevice}
          />
        </Box>

        <Box sx={styles.addedStationsWrapper}>
          <Box sx={styles.addedDevicesTopWrapper}>
            {exceedLimit && (
              <Typography variant="h6" color="primary">
                Limit reached
              </Typography>
            )}

            <Box sx={styles.stringWrapper}>
              {callDestinationAppUnit.length > 0 ? (
                <Typography variant="h6">{callDestinationDevices.length} / 19 device(s) added</Typography>
              ) : (
                <Typography variant="h6">{callDestinationDevices.length} / 20 device(s) added</Typography>
              )}
            </Box>

            {callDestinationAppUnit.length > 0 && (
              <Box sx={styles.stringWrapper}>
                <Typography variant="h6">{callDestinationAppUnit.length} / 1 App group Added</Typography>
              </Box>
            )}
          </Box>

          <Box>
            {callDestinationAppUnit.length > 0 && (
              <SelectedAppUnit appUnit={callDestinationAppUnit[0]} handleRemoveAppUnit={handleRemoveAppUnit} />
            )}
            <SelectedDevice
              devices={callDestinationDevices}
              handleRemoveCallDestinationDevice={handleRemoveCallDestinationDevice}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export const CallDestinationLabel = () => {
  const callDestination = getString('Call_Destination');
  return (
    <>
      <span>{callDestination}</span>
    </>
  );
};

const styles = {
  descriptionWrapper: {
    marginY: '15px'
  },
  controlPanelWrapper: {
    display: 'flex',
    marginY: '20px'
  },
  devicesAndSettingsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '530px',
    overflow: 'auto'
  },
  filterDevicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '220px',
    padding: '20px'
  },
  answeringStationsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
    maxHeight: 'var(--window-height)',
    overflow: 'auto',
    backgroundColor: '#EFEFEF'
  },
  addedStationsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto'
  },
  addedDevicesTopWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginY: '10px'
  },
  stringWrapper: {
    border: '1px solid #C0C0C0',
    borderRadius: '5px',
    padding: '5px',
    margin: '5px',
    color: 'grey'
  }
};

export default CallDestination;
